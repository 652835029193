import {QueryClient} from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';

const queryClient = new QueryClient();


// localStorage'dan user_id alın. Eğer yoksa varsayılan bir değer kullanın.
const storedUserString = localStorage.getItem('user');
let userId;

if (storedUserString) {
	try {
		const storedUser = JSON.parse(storedUserString);
		userId = storedUser?.body?.user?.user_id || '';
	}
	catch (e) {
		userId = null;
	}
}


export const makeReqest = axios.create({
	baseURL: `https://admin.watchturks.online/api`,
	withCredentials: false,
	mode: 'cors',
});

makeReqest.interceptors.request.use((config) => {
	const token = Cookies.get('token')
	console.log(token)
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
		config.headers['X-User-Id'] = userId;
	}
	return config;
});

queryClient.setQueryDefaults({queries: {makeReqest}});

