import React, {useContext} from 'react';
import {useState} from 'react';
import SubscriptionCardUi from '../SubscriptionCardUi/SubscriptionCardUi';
import CaretLeft from './../../../img/CaretLeft.svg';
import classes from './Subscription.module.css';
import {leftMenu} from '../ProfileMain';
import {AuthContext} from '../../../context/authContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const Subscription = () => {
	const {currentUser} = useContext(AuthContext);

	const [fixCard, setIsFixCard] = useState(false);
	const navigate = useNavigate();

	const subCtx = useContext(leftMenu);
	// const currentUser = useContext(AuthContext)

	const showSidebar = () => {
		subCtx();
		setIsFixCard(true);
	};
	const {data} = useQuery({
		queryKey: ['packages'],
		queryFn: () => {
			return axios.get(`https://admin.watchturks.online/api/packages`);
		},
	});
	// console.log(currentUser?.body.user.plan_id);

	// const cancelPlan = useMutation({
	// 	mutationFn: () => {
	// 		return makeReqest.post(
	// 			`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/cancel`
	// 		);
	// 	},
	// 	onSuccess: (data) => {
	// 		toast.success(data?.data.body.message, {
	// 			position: 'bottom-left',
	// 			autoClose: 5000,
	// 			hideProgressBar: false,
	// 			closeOnClick: true,
	// 			pauseOnHover: true,
	// 			draggable: true,
	// 			progress: undefined,
	// 			theme: 'light',
	// 		});
	// 		window.location.reload();
	// 	},
	// 	onError: (err) => {
	// 		return toast.error(err.response.data.error, {
	// 			position: 'bottom-left',
	// 			autoClose: 5000,
	// 			hideProgressBar: false,
	// 			closeOnClick: true,
	// 			pauseOnHover: true,
	// 			draggable: true,
	// 			progress: undefined,
	// 			theme: 'light',
	// 			delay: 1,
	// 		});
	// 	},
	// });

	// function handleCancel() {
	// 	if (window.confirm('Are you sure you want to cancel your plan?')) {
	// 		cancelPlan.mutate();
	// 	}
	// }

	function handleRenew(id) {
		navigate('/choosepayment?plan_id=' + id);
	}

	return (
		<>
			<div className={classes.subscriptionTop}>
				{currentUser?.body.user.subscription && (
					<div className={classes.inner}>
						<img
							onClick={showSidebar}
							className={classes.CaretLeft}
							src={CaretLeft}
							alt="CaretLeft"
						/>
						<h1>Your plan</h1>
						<p>
							Watch all you want with English subs. Recommendations just for you. Change or cancel your plan anytime.
						</p>
						<div className={classes.currentPlan}>
							<div className={classes.planName}>
								<h2>{currentUser?.body.user.subscription}</h2>
								<h2>
									${parseInt(currentUser?.body.user.price)}{' '}
									<span>/{currentUser?.body.user.remaining} days</span>
								</h2>
							</div>
							<div className={classes.planDetails}>
								<ul className={fixCard === true ? `${classes.avtiveUl}` : null}>
									<li>
										{' '}
										<span></span> No Advertisement
									</li>
									<li>
										{' '}
										<span></span> English Subtitles
									</li>
									<li>
										<span></span> Unlimited Devices
									</li>
									<li>
										<span></span> HD Resolution
									</li>
									<li>
										<span></span> New Episodes Notifications
									</li>
								</ul>
							</div>
							<div className={classes.cancelSub}>
								<button
									className={classes.cancelBtn}
									onClick={() => handleRenew(currentUser?.body.user?.plan_id)}
								>
									<span>Renew</span>
								</button>
							</div>
							{/*<div className={classes.cancelSub}>*/}
							{/*	<button*/}
							{/*		className={classes.cancelBtn}*/}
							{/*		onClick={() => handleCancel()}*/}
							{/*	>*/}
							{/*		<span>Cancel</span>*/}
							{/*	</button>*/}
							{/*</div>*/}
						</div>

						<div className={classes.morePlan}>
							{data &&
							 data?.data.body.packages.map((pkg) =>
								 currentUser?.body.user.plan_id == pkg.id ? null : (
									 <SubscriptionCardUi subscriptionPkg={pkg} key={pkg.id}/>
								 )
							 )}
						</div>
					</div>
				)}

				{!currentUser?.body.user.subscription && (
					<div className={classes.inner}>
						<img
							className={classes.CaretLeft}
							onClick={showSidebar}
							src={CaretLeft}
							alt="CaretLeft"
						/>
						<h1>Choose your plan</h1>
						<p>
							Watch all you want with English subs. Recommendations just for
							you. Change or cancel your plan anytime.
						</p>

						<div className={classes.morePlanWSub}>
							{data &&
							 data?.data.body.packages.map((pkg) => (
								 <SubscriptionCardUi subscriptionPkg={pkg} key={pkg.id}/>
							 ))}
						</div>
					</div>
				)}
			</div>
			<ToastContainer limit={1}/>
		</>
	);
};
export default Subscription;
