import React, {useContext, useEffect} from 'react';
import classes from './PaymentPlan.module.css';
import paypal from './../../img/paypal.svg';
import CreditCard from './../../img/CreditCard.svg';
import {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../axios';
import {AuthContext} from '../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Spinner from '../spinner/Spinner';
import {useSearchParams} from 'react-router-dom';
import axios from 'axios';

const PaymentPlan = () => {
    const [processing, setProcessing] = useState(false);
    const [pkgId, setPkgId] = useState(null);
    const [cardType, setCardType] = useState('');
    const token = Cookies.get('token');
    const [activeClass, setActiveClass] = useState('');
    const [searchParams, setSearchParams] = useSearchParams()

    const {data, isLoading} = useQuery({
        queryKey: ['packages'],
        queryFn: () => {
            return axios.get('https://admin.watchturks.online/api/packages');
        },
    });
    const {currentUser} = useContext(AuthContext);

    useEffect(() => {
        const planId = searchParams.get('plan_id');

        if (planId) {
            selectPackage(planId, false);
        }
    })

    const stripePaymentMethod = useMutation({
        mutationFn: () => {
            const userId = currentUser?.body.user.user_id;
            const form = new FormData();
            form.set('user_id', userId);
            form.set('package_id', pkgId);

            return axios.post(`https://admin.watchturks.online/api/subscriptions/${userId}/${pkgId}/stripe/create-checkout-session`, form);
        },
        onSuccess: async (respnse) => {
            window.location.href = respnse.data.session_url;
            setProcessing(false);
        },
    });

    const paypalPaymentMethod = useMutation({
        mutationFn: () => {
            const userId = currentUser?.body.user.user_id;
            const form = new FormData();
            form.set('user_id', userId);
            form.set('package_id', pkgId);

            return axios.post(`https://admin.watchturks.online/api/subscriptions/${userId}/${pkgId}/paypal/create-checkout-session`, form);
            // return makeReqest.post(`https://backend.watchturks.tv/api/subscriptions/${currentUser?.body.user.user_id}/${pkgId}/paypal/create-checkout-session`, {});

        },
        onSuccess: async (respnse) => {
            window.location.href = respnse.data.session_url;
            setProcessing(false);
        },
    });

    const selectPackage = (id, change = true) => {
        if (change) {
            searchParams.set('plan_id', id);
            setSearchParams(searchParams);
        }

        setPkgId(id);
        setActiveClass(id);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        setProcessing(true);

        if (!pkgId || !cardType) {
            toast.warn('Please make a choice', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setProcessing(false);
            return;
        }

        // changePaymentMethod.mutate();
        if (cardType === 'PayPal') handlePaypalPayment();
        if (cardType === 'Stripe') handleStripePayment();
    };

    // const config = {
    //   headers: { Authorization: `Bearer ${token}`, mode: "cors" },
    // };
    // const { data:paypalData } = useQuery({
    //   queryKey: ["paypal",pkgId],
    //   queryFn: () => {
    //     return axios.get(`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`,config)
    //   },
    //   enabled:!!pkgId
    // });
    // if (pkgId) {
    //   fetch(
    //     `${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`,
    //     config
    //   ).then((res) => console.log(res));
    // }
    // console.log(paypalData);
    function handlePaypalPayment() {
        paypalPaymentMethod.mutate();
        // const url = `${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`;
        // const headers = new Headers();
        // headers.append('Authorization', `Bearer ${token}`);
        // window.location.href = url;
        //
        // // Open the Google authentication link in the current tab
        // // const authWindow = window.open(googleAuthUrl, '_blank');
        // // window.addEventListener('message', (event) => {
        // //   if (event.origin === window.location.origin) {
        // // Save the response in local storage
        // // localStorage.setItem('user', JSON.stringify(event.data));
        //
        // // Close the authentication window
        // //   authWindow.close();
        //
        // //   // Move back to the current React application
        // //   window.location.reload();
        // // }
        // // });
    }

    function handleStripePayment() {
        stripePaymentMethod.mutate();
    }

    return (
        <div className={classes.paymentPlanTop}>
            <h1>Please Choose A Plan</h1>
            <p className={classes.paraTop}>You can update your plan or change to another one</p>
            <div className={classes.row}>
                {isLoading ? (
                    <Spinner/>
                ) : (
                    data?.data.body.packages.map((pkg) => (
                        <div key={pkg.id} className={`${classes.bronze} `} onClick={() => selectPackage(pkg.id)}
                             style={{border: activeClass == pkg.id ? '1px solid rgb(19, 157, 224, 0.45)' : '',}}>
				              <span className={classes.inner} onClick={() => selectPackage(pkg.id)}>
				                <h2>{pkg.title}</h2>
				                <p>
				                  ${parseInt(pkg.amount)}{' '}
                                    <span>
				                    /{pkg.duration} {pkg.type === 'days' ? 'days' : pkg.type === 'monthly' && pkg.duration > 1 ? 'months' : pkg.type === 'monthly' && pkg.duration == 1 ? 'month' : pkg.type === 'yearly' ? 'year' : ''}
				                  </span>
				                </p>
				              </span>
                        </div>
                    ))
                )}
            </div>
            <div className={classes.paymentMethod}>
                <h1>Select payment method</h1>
                <div className={classes.rowCard}>
                    {/* <button onClick={() => {setCardType('PayPal');}} style={{border: cardType === 'PayPal' ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}>{' '}*/}
                    {/*	<span>*/}
                    {/*      <img src={paypal} alt="paypal"/> Paypal*/}
                    {/*    </span>*/}
                    {/*</button>*/}

                    <button onClick={() => {
                        setCardType('Stripe');
                    }} style={{border: cardType === 'Stripe' ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}>
			            <span>
			              {' '}
                            <img src={CreditCard} alt="CreditCard"/>
			              Credit Card
			            </span>
                    </button>
                </div>

                <button className={classes.payBtn} onClick={submitHandler} disabled={processing} type="submit"
                        style={{opacity: processing ? '.4' : '1'}}>
                    Pay & Enter
                </button>
            </div>
            <ToastContainer limit={1}/>
        </div>
    );
};
export default PaymentPlan;
