import React, {useContext, useEffect, useState} from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Reviews from '../..//Reviews/Reviews';
import SocialShare from '../../SocialShare/SocialShare';
import classes from './SeriesDetail.module.css';
import star from '../../../img/star.svg';
import Recommended from '../../RecommendedMovie/Recommended';
import {useNavigate, useParams} from 'react-router';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import SeriesEpisodes from '../seriesEpisodes/SeriesEpisodes';
import axios from 'axios';
import {AuthContext} from '../../../context/authContext';
import Spinner from '../../spinner/Spinner';
import bookmark from '../../../img/bookmark.svg';
import filledWatchLaterIcon from '../../../img/BookmarkSimple.svg';
import saveBook from '../../../img/Icons.svg';
import NotificationFilled from '../../../img/notificationFilled.svg';

const SeriesDetail = () => {
    const {id} = useParams();
    // const [seriesId, setSeriesId] = useState(id);
    const [trailerKey, setTrailerKey] = useState('');
    const [series, setSeries] = useState(null);
    const currentUser = useContext(AuthContext);
    const queryClient = useQueryClient();

    const userId = currentUser?.currentUser?.body?.user?.user_id ? '?userId=' + currentUser?.currentUser?.body?.user?.user_id : '';
    const {data, isLoading} = useQuery({
        queryKey: ['seriesDetail', id],
        queryFn: () => {
            return axios.get(`https://admin.watchturks.online/api/series/${id}` + userId);
        },
    });


    const addToWatchList = useMutation({
        mutationFn: () => {
            const form = new FormData();
            form.set('user_id', currentUser?.currentUser?.body.user?.user_id);
            form.set('serie_id', id);

            return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/wishlist/series/${id}`, form);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['projects']);
            queryClient.invalidateQueries(['userHistory']);
            queryClient.invalidateQueries(['watchList']);
            queryClient.invalidateQueries(['seriesDetail']);
        },
    });

    const addToNotification = useMutation({
        mutationFn: () => {
            const form = new FormData();
            form.set('user_id', userId);
            form.set('serie_id', id);

            return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/user_notifications/${id}`, form);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['projects']);
            queryClient.invalidateQueries(['userHistory']);
            queryClient.invalidateQueries(['watchList']);
            queryClient.invalidateQueries(['seriesDetail']);
        },
    });

    // const {data: history} = useQuery({
    // 	queryKey: ['history'],
    // 	queryFn: () => {
    // 		return makeReqest.post(`/history`, {
    // 			series_id: id,
    // 			episode_id: null,
    // 			movie_id: null,
    // 		});
    // 	},
    // 	enabled: !!id,
    // });

    useEffect(() => {
        if (data) {
            setSeries(data?.data.body.series);
            const regex1 = /(?:\?v=)([\w-]+)/;

            // Regular expression to match the video ID (the "v" value) with "youtu.be" format
            const regex2 = /(?:\.be\/)([\w-]+)/;

            // Function to extract the video ID from the URL
            const extractVideoID = (url) => {
                const match1 = url.match(regex1);
                const match2 = url.match(regex2);

                if (match1) {
                    return match1[1];
                } else if (match2) {
                    return match2[1];
                } else {
                    return null;
                }
            };

            // Test with the URLs
            const videoID1 = extractVideoID(data?.data.body.series.serie_trailer);
            setTrailerKey(videoID1);
        }
        // vidRef.current.play();
    }, [data]);
    // useEffect(() => {
    //   if (data) {
    //     setSeries(data?.data.body.series);
    //     const url = new URL(data?.data.body.series.serie_trailer);
    //     const searchParams = new URLSearchParams(url.search);
    //     const videoId = searchParams.get("v");
    //     setTrailerKey(videoId);
    //   }

    //   vidRef.current.play();
    // }, [data]);
    const navigate = useNavigate();
    // console.log(series);
    useEffect(() => {
        // Scroll to the section when the component mounts or the URL changes
        const sectionId = window.location.hash.substring(1);

        const sectionElement = document.getElementById(sectionId);


        if (sectionElement) {
            sectionElement.scrollIntoView({behavior: 'smooth'});
        }
    }, [window.location.pathname]);
    // console.log( data?.data.body.canPlay);
    return (
        <div className={classes.detailTop} id="details">
            <div className={classes.movieInner}>
                <div className={classes.headerTop}>
                    {/* <video ref={vidRef} src={video} loop></video> */}
                    <div className={classes.overlay}
                         style={{backgroundImage: `url('https://admin.watchturks.online/uploads/serie_cover/${data?.data.body.series.serie_cover}')`}}>
                        <Header/>

                        {isLoading ? (
                            <Spinner/>
                        ) : (
                            series && (
                                <div className={classes.movieContent}>
                                    <div className={classes.left}>
                                        <img className={classes.poster}
                                             src={`${process.env.REACT_APP_BASE_URL}/uploads/${series.serie_image}`}
                                             alt="top series"/>
                                        <div className={classes.detailMovie}>
                                            <div className={classes.top}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div className={classes.saveSeries}>
                                                        <h2 style={{marginBottom: '0px'}}>{data?.data.body.series.serie_title}</h2>
                                                        <div>
                                                            {/* <img className={classes.NotificationDesktop} src={bookmark} alt="bookmark" /> */}
                                                            {currentUser.currentUser?.body.user.user_status && (
                                                                <img
                                                                    className={classes.NotificationDesktop}
                                                                    // style={{width:'48px',height:'48px'}}
                                                                    src={data?.data.body.series.is_notification_on ? NotificationFilled : bookmark}
                                                                    // src={bookmark}
                                                                    style={{cursor: 'pointer'}}
                                                                    alt="bookmark"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        addToNotification.mutate();
                                                                    }}
                                                                />
                                                            )}
                                                            {currentUser.currentUser?.body.user.user_status && (
                                                                <img
                                                                    className={classes.bookmarkDesktop}
                                                                    src={data?.data.body.series.is_wishlist ? filledWatchLaterIcon : saveBook}
                                                                    alt="save"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        addToWatchList.mutate();
                                                                    }}
                                                                />
                                                            )}
                                                            {/* <img
                                className={classes.bookmarkDesktop}
                                src={saveBook}
                                alt="saveBook"
                              /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.rating}>
                                                    <div className={classes.newRow}>
                                                        <div className={classes.totalRate}>
                                                            <img src={star} alt="star"/>
                                                            <p>10</p>
                                                        </div>
                                                        <div className={`${classes.subtitle} ${classes.subtitle1}`}>
                                                            <p>Subtitles: Eng</p>
                                                        </div>
                                                        <div className={classes.mobileSaveNot}>
                                                            {/* <img
                                className={classes.NotificationDesktop}
                                src={bookmark}
                                alt="bookmark"
                              /> */}
                                                            {currentUser.currentUser?.body.user.user_status && (
                                                                <img
                                                                    className={classes.NotificationDesktop}
                                                                    // style={{width:'48px',height:'48px'}}
                                                                    src={data?.data.body.series.is_notification_on ? NotificationFilled : bookmark}
                                                                    // src={bookmark}
                                                                    style={{cursor: 'pointer'}}
                                                                    alt="bookmark"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        addToNotification.mutate();
                                                                    }}
                                                                />
                                                            )}
                                                            {/* <img
                                className={classes.bookmarkDesktop}
                                src={saveBook}
                                alt="saveBook"
                              /> */}
                                                            {currentUser.currentUser?.body.user.user_status && (
                                                                <img
                                                                    style={{cursor: 'pointer'}}
                                                                    className={classes.bookmarkDesktop}
                                                                    src={data?.data.body.series.is_wishlist ? filledWatchLaterIcon : saveBook}
                                                                    alt="save"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        addToWatchList.mutate();
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className={classes.genre}>
                                                        {series.genres.map((genre) => `${genre.genre_title}, `)} &nbsp; . &nbsp; {data?.data.body.series.serie_year} &nbsp; . &nbsp; {series.seasons.length} &nbsp; {series.seasons.length <= 1 ? 'Season' : 'Seasons'}
                                                    </p>
                                                </div>
                                                <div className={`${classes.subtitle} ${classes.subtitle2}`}>
                                                    <p>Subtitles: Eng</p>
                                                </div>
                                                <h2>Description</h2>
                                                <p
                                                    className={classes.descrip}
                                                    style={{
                                                        height: '200px',
                                                        // border: "1px solid red",
                                                        overflow: 'hidden',
                                                        // overflowY: "scroll",
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    {/* <style>
                            {`
          ::-webkit-scrollbar {
            width: 6px;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #888;
          }
        `}
                          </style> */}
                                                    {series.serie_description}
                                                </p>
                                            </div>
                                            <div className={classes.btns}>
                                                <button
                                                    className={`${typeof data?.data.body.series.seasons[0]?.episodes[0] === 'undefined' ? 'd-noned' : ''}`}
                                                    onClick={(e) => {
                                                        if (!currentUser.currentUser) return navigate('/signin');
                                                        else
                                                            data?.data.body.canPlay
                                                                ? navigate(`/watchEpisodes/${data?.data.body.series.seasons[0]?.episodes[0].episode_id}`)
                                                                : // (window.location.href = `/watchEpisodes/${data?.data.body.series.seasons[0]?.episodes[0].episode_id}`)
                                                                navigate('/choosepayment');
                                                        e.stopPropagation();
                                                    }}
                                                    style={{cursor: 'pointer'}}
                                                >
                                                    Watch Now
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        let element = document.getElementById('trailer');
                                                        element.scrollIntoView({behavior: 'smooth'});
                                                    }}
                                                    style={{cursor: 'pointer'}}
                                                >
                                                    <span>Play Trailer</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                {series?.seasons.length != 0 &&
                    <SeriesEpisodes canPlay={data?.data.body.canPlay} seasons={series?.seasons} currentSeasonId={null}
                                    fromPlayer={false}/>}

                {series?.casts.length != 0 && (
                    <div className={classes.topcast}>
                        <h2>Top Cast</h2>
                        <div className={classes.row}>
                            {series?.casts.map((cast) => (
                                <div className={classes.cast} key={cast.id}>
                                    <img src={cast.imageUrl} alt="cast"/>
                                    <p>{cast.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className={classes.trailer} id="trailer">
                    <h2>Trailer</h2>
                    {/* <VideoJS options={VideoJsOptions} /> */}
                    {trailerKey && (
                        <iframe
                            style={{
                                maxWidth: '1100px',
                                width: '100%',
                                margin: 'auto',
                                height: '600px',
                                maxHeight: '600px',
                                borderRadius: '20px',
                            }}
                            src={`https://www.youtube.com/embed/${trailerKey}?controls=1`}
                            // title="YouTube video player"
                            frameBorder="0"
                            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            // allowfullscreen
                            // webkitallowfullscreen
                            // mozallowfullscreen
                            allowFullScreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen"
                            msallowfullscreen="msallowfullscreen"
                            oallowfullscreen="oallowfullscreen"
                            webkitallowfullscreen="webkitallowfullscreen"
                        ></iframe>
                    )}
                </div>

                <div className={classes.social}>
                    <SocialShare/>
                    {series && <Reviews seriesId={id} reviews={series.series_reviews}/>}
                </div>
            </div>
            <Recommended/>

            <Footer/>
        </div>
    );
};

export default SeriesDetail;
