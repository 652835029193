import classes from './MoviePlayer.module.css';
import React, {useContext} from 'react';
import Spinner from '../spinner/Spinner';
import SocialShare from '../SocialShare/SocialShare';
import Recommended from '../RecommendedMovie/Recommended';
import {useParams} from 'react-router';
import MoviesReviews from '../MovieDetail/MoviesReviews/MoviesReviews';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {AuthContext} from '../../context/authContext';

const MoviePlayer = () => {
	const {id} = useParams();
	const {currentUser} = useContext(AuthContext);

	const {data, isLoading} = useQuery({
		queryKey: ['watch-movie'],
		queryFn: () => {
			const userId = currentUser?.body.user?.user_id;

			return axios.get(`https://admin.watchturks.online/api/movies/${id}/watch`, {
				params: {
					movieId: id,
					userId: userId,
				}
			});
		},
	});

	return (
		<>
			{isLoading ? (
				<Spinner/>
			) : (
				<div>
					<div className={classes.moviePlayerTop}>
						<div className={classes.headerPlayer}>
							<>
								<h2>{data?.data.body.movie?.movie_title}</h2>
								{/* <img className={classes.info} src={info} alt="info" /> */}
							</>
						</div>
						<div className={classes.videoPlayer}>
							<iframe
								title="myFrame"
								src={data?.data.body.movie.movie_link}
								width="100%"
								height="600"
								frameBorder="0"
								allowFullScreen="allowfullscreen"
								mozallowfullscreen="mozallowfullscreen"
								msallowfullscreen="msallowfullscreen"
								oallowfullscreen="oallowfullscreen"
								webkitallowfullscreen="webkitallowfullscreen"
							></iframe>
						</div>
						<SocialShare/>

						<MoviesReviews
							movieId={data?.data.body.movie.movie_id}
							reviews={data?.data.body.movie.movies_reviews}
						/>
					</div>
					<Recommended/>
				</div>
			)}
		</>
	);
};

export default MoviePlayer;
