import React, {useContext} from 'react';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import SeriesSlider from "../../_SeriesSlider";
import {AuthContext} from '../../../context/authContext';

const RecentSeries = () => {
  const currentUser = useContext(AuthContext);
  const userId = currentUser?.body?.user?.user_id ? '?userId=' + currentUser?.body?.user?.user_id : '';

  const {data, isLoading} = useQuery({
    queryKey: ['home-data'],
    queryFn: () => {
      return axios.get(`https://admin.watchturks.online/api/home` + userId);
    },
  });
  return <SeriesSlider title={"Recent Series"} data={data?.data.body.recentSeries} isLoading={isLoading} />;
};

export default RecentSeries;
