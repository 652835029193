import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useNavigationType} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {AuthContextProvider} from './context/authContext';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';

import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://0eb9cc3ea6ce03f7d14ae06b08d55efb@o4506082366128128.ingest.sentry.io/4506082597928960',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/watchturks\.tv/],
		}),

		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
	<QueryClientProvider client={queryClient}>
		<AuthContextProvider>
			<BrowserRouter basename="/">
				<App/>
			</BrowserRouter>
		</AuthContextProvider>
	</QueryClientProvider>
);
