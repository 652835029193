import React from "react";
import classes from "./SocialShare.module.css";

import twitter from "./../../img/TwitterShare.svg";
import tumble from "./../../img/tumble.svg";

import reddit from "./../../img/RedditLogo.svg";
import whatsappshare from "./../../img/WhatsappLogoshare.svg";
import facebookShare from "./../../img/FacebookShare.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  TumblrShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from "react-share";

const SocialShare = () => {
  return (
    <div className={classes.share}>
      <h2 style={{ marginTop: "100px" }}>Share</h2>
      <div className={classes.row}>
        <FacebookShareButton url={window.location.href}>
          <div>
            <span>
              <img src={facebookShare} alt="facebookShare" />
              <p>Facebook</p>
            </span>
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href}>
          <div>
            <span>
              <img width={32} src={twitter} alt="twitter" />
              <p>X</p>
            </span>
          </div>
        </TwitterShareButton>
        <TumblrShareButton url={window.location.href}>
          <div>
            <span>
              <img src={tumble} alt="tumble" />
              <p>Tumblr</p>
            </span>
          </div>
        </TumblrShareButton>
        <WhatsappShareButton url={window.location.href}>
          <div>
            <span>
              <img src={whatsappshare} alt="whatsappshare" />
              <p>Whatsapp</p>
            </span>
          </div>
        </WhatsappShareButton>
        <RedditShareButton url={window.location.href}>
          <div>
            <span>
              <img src={reddit} alt="reddit" />
              <p>Reddit</p>
            </span>
          </div>
        </RedditShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
