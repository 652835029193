import React, {useContext} from 'react';
import classes from './CreateForm.module.css';
import googleLogo from './../../img/googleLogo.svg';
import {useState} from 'react';
import eyePass from './../../img/Eyepassword.svg';
import eyeTxt from './../../img/Eyetxt.svg';
import {NavLink, useNavigate} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {AuthContext} from '../../context/authContext';
import ReCAPTCHA from "react-google-recaptcha";

const CreateForm = () => {
    const [captchaValue, setCaptchaValue] = useState(null);
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };
    const [passwordType, setPasswordType] = useState('password');
    const {setCurrentUser} = useContext(AuthContext);
    const [checkboxIsChecked, setCheckboxIsChecked] = useState(false);
    const [userInputs, setUserInputs] = useState({
        user_name: '',
        user_email: '',
        user_password: '',
    });
    const {login} = useContext(AuthContext);
    const navigate = useNavigate();
    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    const userSignUp = useMutation({
        mutationFn: () => {
            const form = new FormData();
            form.set('user_name', userInputs.user_name);
            form.set('user_email', userInputs.user_email);
            form.set('user_password', userInputs.user_password);

            return axios.post(`https://admin.watchturks.online/api/auth/register`, form);
        },
        onSuccess: async (response) => {
            try {
                if (response.data.banned === 1) {
                    toast.error('Your account has been banned.', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        delay: 50,
                    });
                    return;
                }

                await login({email: userInputs.user_email, inputPassword: userInputs.user_password});

                toast.success('Successfully registered and logged in', {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    delay: 50,
                });

                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);

            } catch (loginError) {
                // Giriş sırasında hata olursa
                toast.error(loginError.response.data.error, {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    delay: 50,
                });
            }
        },
        onError: (err) => {
            // Kayıt sırasında hata olursa
            toast.error(err.response.data.error, {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                delay: 50,
            });
        },
    });


    const handleChange = (e) => {
        setUserInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userInputs.user_name.trim() || !userInputs.user_email.trim() || !userInputs.user_password.trim()) {
            toast.warn("Please fill in all fields.", {});
            return;
        }
        if (!captchaValue) {
            toast.warn("Please complete the reCAPTCHA verification.", {});
            return;
        }

        if (!checkboxIsChecked) {
            toast.warn("Please agree to the Terms & Conditions.", {});
            return;
        }

        try {
            await userSignUp.mutate();
        } catch (err) {
            toast.error(err.response.data.error, {});
        }
    };

    function handleGoogleSignIn() {
        if (!captchaValue) {
            toast.warn("Please complete the reCAPTCHA verification before signing in with Google.", {});
            return;
        }

        const googleAuthUrl = 'https://admin.watchturks.online/api/auth/google';
        const authWindow = window.open(googleAuthUrl, '_self');
    }

    return (
        <div className={classes.CreateAcc}>
            <h1>Welcome to WatchTurks</h1>

            <button className={classes.googleBtn} onClick={handleGoogleSignIn}>
                <span>
                    <img src={googleLogo} alt="googleLogo"/>
                    Sign up with Google
                </span>
            </button>
            <div className={classes.orRow}>
                <span className={classes.line}></span>
                <p>OR</p>
                <span className={classes.line}></span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={classes.inputStroke}>
                    <span>
                        <input
                            type="text"
                            placeholder="Your Name"
                            name="user_name"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className={classes.inputStroke}>
                    <span>
                        <input
                            type="email"
                            placeholder="E-mail"
                            name="user_email"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className={classes.pass}>
                    <span>
                        <input
                            type={passwordType}
                            onChange={handleChange}
                            name="user_password"
                            placeholder="Password"
                        />
                        <div className="input-group-btn">
                            <button type="button" onClick={togglePassword}>
                                {passwordType === 'password' ? (
                                    <img src={eyePass} alt="eyePass"/>
                                ) : (
                                    <img src={eyeTxt} alt="eyeTxt"/>
                                )}
                            </button>
                        </div>
                    </span>
                </div>
                <div className={classes.container}>
                    <ReCAPTCHA
                        sitekey="6LdxHkEpAAAAALseCPjHmIDwEQsMv0HbuSL5DI-u"
                        onChange={handleCaptchaChange}
                    />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                    <input
                        type="checkbox"
                        onClick={() => setCheckboxIsChecked((prev) => !prev)}
                        style={{width: '20px', height: '20px'}}
                    />
                    <p>I agree with <a href="/term-condition">Terms & Conditions</a></p>
                </div>
                <button
                    disabled={!checkboxIsChecked}
                    className={classes.createBtn}
                    style={{
                        background: checkboxIsChecked ? '' : '#ffffff',
                        color: checkboxIsChecked ? '' : 'gray',
                    }}
                    type="submit"
                >
                    Create an account
                </button>
            </form>
            <span>
                Already have an account?{' '}
                <NavLink to="/signin" style={{color: 'white'}}>
                    {' '}
                    Sign In
                </NavLink>
            </span>
            <ToastContainer limit={1}/>
        </div>
    );
};

export default CreateForm;
