import React, {useContext} from "react";
import classes from "./CopyrightPolicy.module.css";
import ShieldCheck from "./../../../img/ShieldCheck.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import {leftMenu} from "../ProfileMain";

const CopyrightPolicy = () => {
    const ctxPrivacy = useContext(leftMenu);
    const linkStyle = {
        fontWeight: 'bold',
        color: '#9e9eff'
    };
    const showSidebar = () => {
        ctxPrivacy();
    };

    return (
        <div className={classes.privacyPolicyTop}>
            <div className={classes.privacyHeader}>
                <img
                    className={classes.CaretLeft}
                    onClick={showSidebar}
                    src={CaretLeft}
                    alt="CaretLeft"
                />
                <img
                    className={classes.ShieldCheck}
                    src={ShieldCheck}
                    alt="ShieldCheck"
                />
                <h1>Copyright Policy for Watch Turks</h1>
            </div>

            <div className={classes.detail}>
                <p className={classes.mb24}>
                    At Watch Turks, we are committed to respecting copyright laws and ensuring that our use of
                    illustrations, texts, images, or videos aligns with legal standards, specifically under the
                    principles of "Fair Use" as outlined in copyright legislation. Our primary aim is to make the
                    Turkish language and culture accessible to non-Turkish speaking audiences worldwide, through the
                    provision of a Turkish to English subtitle translation service.
                </p>
                <h3>Use of Materials Under Fair Use</h3>
                <p className={classes.mb16}>
                    The content featured on our website, including videos, images, and audio clips, is carefully
                    selected to serve educational purposes, foster knowledge, and provide a comprehensive experience of
                    Turkish culture. This content is primarily aimed at assisting individuals in learning the Turkish
                    language and exploring its cultural richness.

                </p>

                <h2>1- Educational Purpose</h2>
                <p>Our resources are tailored to support language learning and cultural education. We believe in the
                    power of visual and auditory materials in enhancing understanding and retention. That’s why we
                    support our subtitles with the video.</p>

                <h2>2- Non-commercial Use</h2>
                <p>The services provided by Watch Turks are designed to translate subtitles. We sell only our subtitle
                    files and get revenue from these files.</p>

                <h2>3- Attribution</h2>
                <p>Where possible, we credit the original creators of the materials used on our site. The legal rights
                    of all videos, images, and sounds featured on Watch Turks belong to their respective producers.</p>

                <h2>4- Transformation</h2>
                <p>We add value by translating content into English, making it accessible to a wider audience. This
                    transformational use falls within the scope of Fair Use, allowing us to create new visual aesthetics
                    that impart knowledge and educate viewers about Turkish culture.</p>


                <h3>Legal Framework</h3>

                <p className={classes.mb16}>
                    Our operation adheres to the Copyright Act, employing materials in a manner that respects the
                    original creators' rights. For detailed information on copyright exceptions and the principles of
                    Fair Use, visitors are encouraged to consult official guidance provided by legal authorities, such
                    as the guidance available at <a style={linkStyle} href={"https://www.gov.uk/guidance/exceptions-to-copyright"}>Exceptions to Copyright.</a>

                </p>

            <h2>Contact Information</h2>

                <p className={classes.mb24}>
                    For inquiries regarding our copyright policy or if you believe that any content on Watch Turks infringes on your copyright, please contact us on admin@watchturks.com . We take such concerns seriously and are committed to resolving them in compliance with copyright laws.

                </p>

                <p>
                    Regards, <br/>
                    Watch Turks
                </p>
            </div>
        </div>
    );
};

export default CopyrightPolicy;
