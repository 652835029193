import React from "react";
import classes from "./NotSubscribe.module.css";
import info from "./../../img/Info2.svg";
import { useNavigate } from "react-router-dom";
const NotSubscribe = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/choosepayment");
  };
  return (
    <div className={classes.notSubTop}>
      <div>
        <img src={info} alt="info" />
        <p>
          Not available <br /> without subscription
        </p>
        <button onClick={handleNavigate}>Choose a plan</button>
      </div>
    </div>
  );
};
export default NotSubscribe;
