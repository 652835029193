import React, {useState, useEffect} from 'react';
import axios from 'axios';
import classes from './TechnicalSupport.module.css';

const TechnicalSupport = () => {
    const [ipAddress, setIpAddress] = useState('');
    const [isVpnActive, setIsVpnActive] = useState(false);
    const [cdnStatus, setCdnStatus] = useState({
        Test1: {status: null, duration: null, statusCode: null},
        Test2: {status: null, duration: null, statusCode: null},
        Test3: {status: null, duration: null, statusCode: null}
    });
    const [adminStatus, setAdminStatus] = useState({
        Test1: {status: null, duration: null, statusCode: null},
        Test2: {status: null, duration: null, statusCode: null},
        Test3: {status: null, duration: null, statusCode: null}
    });
    const [cdnError, setCdnError] = useState('');
    const [adminError, setAdminError] = useState('');
    const [downloadSpeed, setDownloadSpeed] = useState(null);
    const [setUploadSpeed] = useState(null);

    useEffect(() => {
        fetchIpAddress();
        checkCdnStatus();
        checkAdminStatus();
    }, []);

    const fetchIpAddress = async () => {
        try {
            const response = await axios.get('https://api64.ipify.org?format=json');
            const ip = response.data.ip;
            setIpAddress(ip);
            checkVpnStatus(ip);
        } catch (error) {
            console.error('Failed to fetch IP address:', error.message);
        }
    };

    const checkVpnStatus = async (ip) => {
        try {
            const response = await axios.get(`http://proxycheck.io/v2/${ip}?key=jm9295-117683-791402-087746&vpn=1&asn=1`);

            if (response.data[ip]) {
                const isVpnActive = response.data[ip].proxy === 'yes';
                setIsVpnActive(isVpnActive);
            } else {
                console.error('IP data not found in response.');
            }
        } catch (error) {
            console.error('Failed to check VPN status:', error.message);
        }
    };

    const clearBrowserCache = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    };

    const performRequest = async (url, testName) => {
        try {
            const start = Date.now();
            const response = await axios.get(url);
            const duration = Date.now() - start;
            return {status: response.status === 200, duration, statusCode: response.status};
        } catch (error) {
            console.error(`Request for ${testName} failed:`, error.message);
            console.error('Status Code:', error.response ? error.response.status : 'No response received');
            return {
                status: false,
                duration: null,
                statusCode: error.response ? error.response.status : 'No response received'
            };
        }
    };

    const checkCdnStatus = async () => {
        const cdnTests = ['Test1', 'Test2', 'Test3'];
        for (let i = 0; i < cdnTests.length; i++) {
            const cdnStatusResult = await performRequest('https://cdn6.watchturks.tv/player/01e14bb9-7e38-4564-a10c-472033c1f925', cdnTests[i]);
            setCdnStatus(prevStatus => ({...prevStatus, [cdnTests[i]]: cdnStatusResult}));
        }
    };

    const checkAdminStatus = async () => {
        const adminTests = ['Test1', 'Test2', 'Test3'];
        for (let i = 0; i < adminTests.length; i++) {
            const adminStatusResult = await performRequest('https://admin.watchturks.online', adminTests[i]);
            setAdminStatus(prevStatus => ({...prevStatus, [adminTests[i]]: adminStatusResult}));
        }
    };


    return (
        <div className={classes.subscriptionTop}>
            <h3>Technical Support Screen</h3>
            <h5>You can manage technical details on this screen. None of the information on this screen is saved, and
                only you can see it.</h5>
            <p className={classes.ipAddressInfo}>IP Address: {ipAddress}</p>
            <p className={classes.userAgentInfo}>User-Agent: {navigator.userAgent}</p>
            {isVpnActive && <p>VPN Active: Yes</p>}

            <button className={classes.clearCacheBtn} onClick={clearBrowserCache}>
                Clear All Browser Cache and Re Login
            </button>

            <div className={classes.testContainer}>
                <div className={classes.testItem}>
                    <button className={classes.testBtn} onClick={checkCdnStatus}>
                        Cdn Server Test
                    </button>
                    {Object.keys(cdnStatus).map(testName => (
                        <div key={testName}>
                            <p className={classes.statusText}>
                                {testName}: {cdnStatus[testName].status === null ? 'Checking...' : cdnStatus[testName].status ? `Success (${cdnStatus[testName].duration} ms)` : `Failed`}
                            </p>
                            {cdnStatus[testName].statusCode &&
                                <p className={classes.statusCode}>Status Code: {cdnStatus[testName].statusCode}</p>}
                        </div>
                    ))}
                    {cdnError && <p className={classes.errorText}>{cdnError}</p>}
                </div>
                <div className={classes.testItem}>
                    <button className={classes.testBtn} onClick={checkAdminStatus}>
                        Backend Server Test
                    </button>
                    {Object.keys(adminStatus).map(testName => (
                        <div key={testName}>
                            <p className={classes.statusText}>
                                {testName}: {adminStatus[testName].status === null ? 'Checking...' : adminStatus[testName].status ? `Success (${adminStatus[testName].duration} ms)` : `Failed`}
                            </p>
                            {adminStatus[testName].statusCode &&
                                <p className={classes.statusCode}>Status Code: {adminStatus[testName].statusCode}</p>}
                        </div>
                    ))}
                    {adminError && <p className={classes.errorText}>{adminError}</p>}
                </div>
            </div>

        </div>
    );
};

export default TechnicalSupport;
