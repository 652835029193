import React, {useState, useEffect, useContext} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Next from "./../../../StaticPic/Next.png";
import classes from "./TopMovies.module.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Spinner from "../../spinner/Spinner";
import SliderMoviesCard from "../_SliderMovieCard";
import SliderCardInfo from "../_SliderMovieCard/SliderCardInfo";
import {AuthContext} from '../../../context/authContext';

let slidesToShow = 5;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick} style={{ left: "-60px", zIndex: "90", width: "50px", height: "50px" }}>
        <img className={classes.carouselPrev} src={Next} alt="next" />
      </div>
    </>
  );
};

const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick} style={{ right: "-30px" }}>
        <img className={classes.carouselNext} src={Next} alt="next" />
      </div>
    </>
  );
};

const TopMovies = () => {
  const currentUser = useContext(AuthContext);
  const [topTen, setTopTen] = useState(null);
  const [currentMovie, setCurrentMovie] = useState(null);
  const [infoStyle, setInfoStyle] = useState(null);
  const [isClickAllowed, setIsClickAllowed] = useState(true);

  const carouselProperties = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2050,
        settings: {
          slidesToShow: 4,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      setIsClickAllowed(false);
      setCurrentMovie(topTen[next]);
    },
    afterChange: (current) => {
      setIsClickAllowed(true);
      setPos();
    },
  };
  const userId = currentUser?.body?.user?.user_id ? '?userId=' + currentUser?.body?.user?.user_id : '';

  const { data, isLoading } = useQuery({
    queryKey: ["home-data"],
    queryFn: () => {
      return axios.get(`https://admin.watchturks.online/api/home` + userId);
    },
  });
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
    setPos();
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    setTopTen(data?.data.body.topMovies);
    setCurrentMovie(data?.data.body.topMovies[0]);
    setPos();
  }, [data]);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 2;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 3;
  } else if (width > 1025 && width <= 1440) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  const setPos = () => {
    const parentElement = document.getElementById("topTenMovies");
    const divElement = parentElement?.querySelector(".slick-current");
    const imageElement = divElement?.querySelector(".CardImage");
    const width = imageElement?.offsetWidth + "px";
    const height = imageElement?.offsetHeight + "px";
    const rect = imageElement?.getBoundingClientRect();
    const top = rect?.top + "px";
    const left = rect?.left + "px";

    setInfoStyle({ "--top": top, "--left": left, "--wid": width, "--hei": height });
  };

  return (
    <div id="topTenMovies" className={classes.carousel}>
      <h2>Top Movies</h2>
      <div className={width > 1024 ? "topTenMovieContainer" : "topTenMovieContainerMobile"}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Slider {...carouselProperties}>
            {data &&
              data?.data.body.topMovies.map((item, index) => (
                <div id={`topten-${index}`} className={classes.card} key={item.movie_id}>
                  <SliderMoviesCard movie={item} order={index} isClickAllowed={isClickAllowed} />
                </div>
              ))}
          </Slider>
        )}
        {currentMovie && <SliderCardInfo movie={currentMovie} infoStyle={infoStyle} />}
      </div>
    </div>
  );
};

export default TopMovies;
