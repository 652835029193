import React, {useContext, useEffect, useState} from 'react';
import classes from './Header.module.css';
import logo from './../../img/Logo.svg';
import SlidersHorizontal from './../../img/SlidersHorizontal.svg';
import MagnifyingGlass from './../../img/MagnifyingGlass.svg';
import User from './../../img/User.svg';
import menuIcon from './../../img/List.svg';
import notifyy from './../../img/notification.svg';
import pArrow from './../../img/pArrow.png';
import watchLater from './../../img/watchlater.svg';
import PencilSimple from './../../img/PencilSimple.svg';
import MonitorPlay from './../../img/MonitorPlay.svg';
import ShieldCheck from './../../img/ShieldCheck.svg';
import ListBullets from './../../img/ListBullets.svg';
import ChatCircleDots from './../../img/ChatCircleDots.svg';
import SignOut from './../../img/SignOut.svg';
import Logo from './../../img/Logo.svg';
import clock from './../../img/Clock.svg';
import cross from './../../img/Cross.svg';
import info from './../../img/Info.svg';
import homeIcon from './../../img/home-4.svg';
import barChart from './../../img/bar-chart-horizontal.svg';
import barMovieChart from './../../img/ph_film-script.svg';
import profileLogin from './../../img/Profile.svg';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import {AuthContext} from '../../context/authContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {makeReqest} from '../../axios';
import Search from '../Search/Search';

const Header = () => {
    const [showProfileMenu, setIsShowProfileMenu] = useState(false);
    const [showNotification, setIsShowNotification] = useState(false);
    const [showMobileMenu, setIsShowMobileMenu] = useState(false);
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        try {
            setQuery(e.target.value);
        } catch (e) {
        }
    };

    const {data: searchResults} = useQuery({
        queryKey: ['search', query],
        queryFn: () => {
            const postData = new FormData();
            if (currentUser) {
                postData.append('user_id', currentUser.body.user.user_id);
            }
            postData.append('q', query);

            return axios.post('https://admin.watchturks.online/api/search', postData);
        },
        enabled: !!query,
    });

    const [notification, setNotification] = useState(null);
    const {data: notificationxx} = useQuery({
        queryKey: ['notification'],
        queryFn: () => {
            if (currentUser)
                axios.get(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/notifications`).then((res) => {
                    setNotification(res.data.body.notifications);
                });
            return null;
        },
    });

    useEffect(() => {
    }, []);

    const openMobileMenu = () => {
        setIsShowMobileMenu(!showMobileMenu);
    };

    const showProfileHandlerToggle = () => {
        setIsShowProfileMenu(!showProfileMenu);
    };

    const showNotifHandlerToggle = () => {
        setIsShowNotification(!showNotification);
    };

    const {currentUser, setCurrentUser} = useContext(AuthContext);

    const notificationHandler = (notifId, serieId) => {
        const form = new FormData();
        form.set('notification_id', notifId);
        form.set('user_id', currentUser?.body.user?.user_id);

        axios.post(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/notifications`, form)
            .then((res) => {
                navigate(`/watchEpisodes/${serieId}`);
            })
            .catch(() => {
                navigate(`/watchEpisodes/${serieId}`);
            });
    };

    const logoutHandler = () => {
        localStorage.removeItem('user');
        setCurrentUser(null);
    };

    // for mobile search
    const [showMobileSearch, setIsShowMobileSearch] = useState(true);
    const [setShowMobileSearchList, setIsShowMobileSearchList] = useState(false);
    // andler for show mobile Search ===
    const showMobileSearchHanlder = () => {
        setIsShowMobileSearchList(!setShowMobileSearchList);
    };
    const [promotionText, setPromotionText] = useState(null);

    useEffect(() => {
        axios.get('https://admin.watchturks.online/api/get-promotion-text')
            .then((response) => {
                const responseData = response.data?.body;
                if (responseData && responseData.text && responseData.color) {
                    const {text, color} = responseData;
                    setPromotionText({text, color});
                } else {
                    // console.error('Invalid promotion text data received:', responseData);
                }
            })
            .catch((error) => {
                console.error('Error fetching promotion text:', error);
            });
    }, []);

    return (
        <div className={classes.HeaderTop}>
            {promotionText && (
                <div
                    className={`${classes.marqueeContainer} ${promotionText.color === 'red' ? classes.promotionRed : classes.promotionBlue}`}>
                    <span className={classes.marqueeText}>{promotionText.text}</span>
                </div>
            )}


            <div
                className={
                    showMobileMenu
                        ? `${classes.MobileMenuTop} ${classes.MobileMenuTopActive}`
                        : `${classes.MobileMenuTop}`
                }
            >
                <div className={classes.menuForMobile}>
                    <div className={classes.innerMenu}>
                        <div className={classes.header}>
                            <NavLink to="/">
                <span>
                  <img src={Logo} alt="logo"/>
                  <h3>WatchTurks </h3>
                </span>
                            </NavLink>
                            <img src={cross} onClick={openMobileMenu} alt="cros"/>
                        </div>

                        <ul>
                            <NavLink to="/">
                                <li>
                                    <img src={homeIcon} alt="homeIcon"/> Home
                                </li>
                            </NavLink>
                            <NavLink to="/allseries">
                                <li>
                                    <img src={barChart} alt="barChart"/> Turkish Series
                                </li>
                            </NavLink>
                            <NavLink to="/allmovies">
                                <li>
                                    <img src={barMovieChart} alt="barMovieChart"/> Turkish Movies
                                </li>
                            </NavLink>
                            {currentUser ? (
                                <NavLink to="/watch-later">


                                    <li><img src={watchLater} alt="watchLater"/> Favorites</li>
                                </NavLink>
                            ) : (
                                ''
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '20px',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                    color: 'white',
                                    padding: '24px 0px',
                                    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                                    opacity: '0.6',
                                }}
                            >
                                <img src={profileLogin} alt="profileLogin"/>
                                <p>
                                    {currentUser ? (
                                        <NavLink to="/edit-profile" style={{color: 'white'}}>
                                            Profile
                                        </NavLink>
                                    ) : (
                                        <NavLink to="/signin" style={{color: 'white'}}>
                                            Login
                                        </NavLink>
                                    )}
                                </p>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={classes.headerInner}>
                <div className={classes.left}>
                    <NavLink to="/">
                        <img className={classes.logo} src={logo} alt="logo"/>
                    </NavLink>
                    <ul className={classes.ul}>
                        {!currentUser && (
                            <>
                                <NavLink to="/">
                                    {' '}
                                    <li>Home</li>
                                </NavLink>
                                <NavLink to="/services">
                                    <li>Services</li>
                                </NavLink>
                            </>
                        )}
                        {currentUser ? (
                            <NavLink to="/allseries">
                                <li>Turkish Series</li>
                            </NavLink>
                        ) : null}
                        {currentUser ? (
                            <NavLink to="/allmovies">
                                <li>Turkish Movies</li>
                            </NavLink>
                        ) : null}
                        {currentUser ? (
                            <NavLink to="/watch-later"
                                     style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                                <img src={watchLater} alt="watchLater"/>
                                <li style={{marginLeft: '8px'}}>Favorites</li>
                            </NavLink>
                        ) : (
                            ''
                        )}

                    </ul>
                    {currentUser && (

                        <form onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="text"
                                placeholder="Search movies, series, actors"
                                onChange={handleSearch}
                            />

                            <div className={classes.searchDropDown}>
                                {searchResults?.data.body.movies && searchResults?.data.body.movies.map((movie) => (
                                    <div
                                        className={classes.searchMovieList}
                                        onClick={() => navigate(`/moviedetail/${movie.movie_id}`)}
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/uploads/${movie.movie_image}`}
                                            alt="Movie"
                                        />
                                        <div>
                                            <h3>{movie.movie_title}</h3>
                                            <p>
                                                <span className={classes.badge}>Movie</span> &nbsp; · &nbsp;{movie.movie_year}-...
                                            </p>
                                        </div>
                                    </div>
                                ))}
                                {searchResults?.data.body.series && searchResults.data.body.series.map((serie) => (
                                    <div
                                        className={classes.searchMovieList}
                                        onClick={() => navigate(`/seriesdetail/${serie.serie_id}`)}
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/uploads/${serie.serie_image}`}
                                            alt="Movie"
                                        />
                                        <div>
                                            <h3>{serie.serie_title}</h3>
                                            <p>
                                                <span className={classes.badge}>Serie</span> &nbsp; · &nbsp;{serie.serie_year}-...
                                            </p>
                                        </div>
                                    </div>
                                ))}
                                {/*{(searchResults?.data.body.movies.length > 0 ||*/}
                                {/*  searchResults?.data.body.series.length > 0) && (*/}
                                {/*	 // <div*/}
                                {/*		//  className={classes.btnSearchAll}*/}
                                {/*		//  onClick={() => navigate('/search', {state: query})}*/}
                                {/*	 // >*/}
                                {/*		//  <button>*/}
                                {/*		// 	 <span>See all results</span>*/}
                                {/*		//  </button>*/}
                                {/*	 // </div>*/}
                                {/* )}*/}
                            </div>
                            <span></span>

                            {/* for mobile search  */}
                            <div className={classes.mobileSearch}>
                                <img
                                    style={{marginRight: '20px', height: '28px'}}
                                    onClick={showMobileSearchHanlder}
                                    className={classes.mobileSearchIcon}
                                    src={MagnifyingGlass}
                                    alt="MagnifyingGlass"
                                />

                                {setShowMobileSearchList && (
                                    <div className={classes.mobileForm}>
                                        <form>
                                            <input
                                                type="text"
                                                placeholder="Movies, series, actors"
                                                onChange={handleSearch}
                                            />
                                        </form>
                                        {showMobileSearch && (
                                            <div className={classes.searchDropDownMobile}>
                                                {searchResults?.data.body.movies && searchResults.data.body.movies.map((movie) => (
                                                    <div
                                                        className={classes.searchMovieListMobile}
                                                        onClick={() => navigate(`/moviedetail/${movie.movie_id}`)}
                                                    >
                                                        <img
                                                            src={`${process.env.REACT_APP_BASE_URL}/uploads/${movie.movie_image}`}
                                                            alt="Movie"
                                                        />
                                                        <div>
                                                            <h3>{movie.movie_title}</h3>
                                                            <p>
                                                                <span>10</span> &nbsp; · &nbsp;{movie.movie_year}
                                                                -...
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                                {searchResults?.data.body.series.map((serie) => (
                                                    <div
                                                        className={classes.searchMovieListMobile}
                                                        onClick={() => navigate(`/seriesdetail/${serie.serie_id}`)}
                                                    >
                                                        <img
                                                            src={`${process.env.REACT_APP_BASE_URL}/uploads/${serie.serie_image}`}
                                                            alt="Movie"
                                                        />
                                                        <div>
                                                            <h3>{serie.serie_title}</h3>
                                                            <p>
                                                                <span>10</span> &nbsp; · &nbsp;{serie.serie_year}
                                                                -...
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/*{(searchResults?.data.body.movies.length > 0 ||*/}
                                                {/*  searchResults?.data.body.series.length > 0) && (*/}
                                                {/*	 <div*/}
                                                {/*		 className={classes.btnSearchAll}*/}
                                                {/*		 onClick={() => navigate('/search', {state: query})}*/}
                                                {/*	 >*/}
                                                {/*		 <button>*/}
                                                {/*			 <span>See all results</span>*/}
                                                {/*		 </button>*/}
                                                {/*	 </div>*/}
                                                {/* )}*/}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* mobile search end  */}
                            {/* notification for mobile screen start  */}
                            {!currentUser?.body.user.user_status == '0' && (
                                <div className={classes.notification} onClick={showNotifHandlerToggle}>
                <span>
                  <img className={classes.menuIcon} src={notifyy} alt="notifyy"/>
                </span>
                                    {showNotification && (
                                        <div className={classes.notificationDropDown}>
                                            <div className={classes.notificationHeader}>
                                                <img className={classes.menuIcon} src={notifyy} alt="notifyy"/>
                                                <h3>Notification</h3>
                                            </div>
                                            {notification?.map((item) => (
                                                <a onClick={() => notificationHandler(item.id, item.item_id)}>
                                                    <div className={classes.lefttt}>
                                                        <h3>{item.content}</h3>
                                                        <p>{item.title}</p>
                                                    </div>
                                                    <span className={classes.dot}></span>
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                            <img className={classes.menuIcon} onClick={openMobileMenu} src={menuIcon} alt="menuIcon"/>
                        </form>

                    )}
                </div>
                <div className={classes.right}>

                    <div className={classes.profilePic}>
                        <p>
                            {currentUser ? (
                                currentUser.body.user.user_name
                            ) : (
                                <NavLink to="/signin" style={{color: 'white'}}>
                                    Login
                                </NavLink>
                            )}
                        </p>

                        {/* notification for desktop */}
                        {!currentUser?.body.user.user_status == '0' && (
                            <div className={classes.notification} onClick={showNotifHandlerToggle}>
                <span>
                  <img className={classes.menuIcon} src={notifyy} alt="notifyy"/>
                </span>
                                {showNotification && (
                                    <div className={classes.notificationDropDown}>
                                        <div className={classes.notificationHeader}>
                                            <img className={classes.menuIcon} src={notifyy} alt="notifyy"/>
                                            <h3>Notification</h3>
                                        </div>
                                        {notification?.map((item) => (
                                            <a onClick={() => notificationHandler(item.id, item.item_id)}>
                                                <div className={classes.lefttt}>
                                                    <h3>{item.content}</h3>
                                                    <p>{item.title}</p>
                                                </div>
                                                <span className={classes.dot}></span>
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className={classes.profile}>
              <span onClick={showProfileHandlerToggle}>
                <img src={User} alt="user"/>
              </span>
                            {showProfileMenu && (
                                <div className={classes.dropDown}>
                                    <img className={classes.arrowTop} src={pArrow} alt="pArrow"/>
                                    <Link to="/signin" onClick={logoutHandler}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <img
                                                src={JSON.parse(localStorage.getItem('user')) == null ? User : SignOut}
                                                alt="SignOut"
                                                style={{width: '25px', height: '25px'}}
                                            />
                                            <h3>{JSON.parse(localStorage.getItem('user')) == null ? 'Login' : 'Log Out'}</h3>
                                        </div>
                                    </Link>
                                    {JSON.parse(localStorage.getItem('user')) && (
                                        <Link to="/history" onClick={showProfileHandlerToggle}>
                                            <img src={clock} alt="clock"/>
                                            <h3>History</h3>
                                        </Link>
                                    )}

                                    {JSON.parse(localStorage.getItem('user')) && (
                                        <Link to="/watch-later" onClick={showProfileHandlerToggle}>
                                            <img src={watchLater} alt="watchLater"/>
                                            <h3>Favorites</h3>
                                        </Link>
                                    )}

                                    {JSON.parse(localStorage.getItem('user')) && (
                                        <Link to="/edit-profile" onClick={showProfileHandlerToggle}>
                                            <img src={PencilSimple} alt="PencilSimple"/> <h3>Edit profile</h3>
                                        </Link>
                                    )}

                                    {JSON.parse(localStorage.getItem('user')) && (
                                        <Link to="/subscription" onClick={showProfileHandlerToggle}>
                                            <img src={MonitorPlay} alt="MonitorPlay"/>
                                            <h3>Subscription</h3>
                                        </Link>
                                    )}

                                    <Link to="/contact" onClick={showProfileHandlerToggle}>
                                        <img src={ChatCircleDots} alt="ChatCircleDots"/>
                                        <h3>Contact Us</h3>
                                    </Link>

                                    <Link to="/faq" onClick={showProfileHandlerToggle}>
                                        <img src={info} alt="info"/>
                                        <h3>FAQs</h3>
                                    </Link>

                                    <Link to="/privacy-policy" onClick={showProfileHandlerToggle}>
                                        <img src={ShieldCheck} alt="ShieldCheck"/>
                                        <h3>Privacy Policy</h3>
                                    </Link>

                                    <Link to="/term-condition" onClick={showProfileHandlerToggle}>
                                        <img src={ListBullets} alt="ListBullets"/>
                                        <h3>Terms & Conditions</h3>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;
