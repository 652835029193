import React, {useContext} from "react";
import classes from "./Footer.module.css";
import logo from "./../../img/Logo.svg";
import FacebookLogo from "./../../img/FacebookLogo.svg";
import TwitterLogo from "./../../img/TwitterLogo.svg";
import YoutubeLogo from "./../../img/YoutubeLogo.svg";
import InstagramLogo from "./../../img/InstagramLogo.svg";
import WhatsappLogo from "./../../img/WhatsappLogo.svg";
import googleplay from "./../../img/googleplay.svg";
import {Link} from "react-router-dom";
import HomeSliderTop from "../Home/HomeSliderTop/HomeSliderTop";
import TopSeriesNew from "../Home/_TopSeries";
import PaymentDetail from "../Home/PaymentDetail/PaymentDetail";
import RecentSeries from "../Home/RecentSeries/RecentSeries";
import LatestEpisode from "../Home/LatestEpisode/LatestEpisode";
import TopMoviesNew from "../Home/_TopMovies";
import RecentMovies from "../Home/RecentMovies/RecentMovies";
import {AuthContext} from "../../context/authContext";

const Footer = () => {
    const {currentUser} = useContext(AuthContext);

    return (
        <div className={classes.footerTop}>
            <div className={classes.row}>
                <div className={classes.col}>
                    <Link to="/" className={classes.logo}>
                        <img src={logo} alt="logo"/>
                        <h1>WatchTurks</h1>
                    </Link>
                    <p>
                        Watch Turkish Series and Movies with English Subtitles in HD
                        Quality!
                    </p>
                </div>
                {currentUser ? (
                    <>
                        <div className={classes.col}>
                            <h2>Explore</h2>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/allseries">Turkish Series</Link>
                                </li>
                                <li>
                                    <Link to="/allmovies">Turkish Movies</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                {/* <li>
              <Link to="/edit-profile">Profile</Link>
            </li> */}
                            </ul>
                        </div>
                    </>
                ) : (
                    <div></div>
                )}

                <div className={classes.col}>
                    <h2>Company</h2>
                    <ul>
                        <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>

                        <li>
                            <Link to="/term-condition">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/copyright-policy">Copyright Policy</Link>
                        </li>

                        <li>
                            <Link to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className={classes.col}>
                    <div className={classes.social}>
                        <a href="https://facebook.com/watchturks">
                            <img src={FacebookLogo} alt="FacebookLogo"/>
                        </a>
                        <a href="https://twitter.com/watchturks">
                            <img width={25} src={TwitterLogo} alt="TwitterLogo"/>
                        </a>
                        <a href="https://www.youtube.com/@watchturks">
                            {" "}
                            <img src={YoutubeLogo} alt="YoutubeLogo"/>
                        </a>
                        <a href="https://instagram.com/watchturks">
                            <img src={InstagramLogo} alt="InstagramLogo"/>
                        </a>
                        <a href="https://wa.me/447312383516">
                            <img src={WhatsappLogo} alt="WhatsappLogo"/>
                        </a>
                    </div>
                    <p>We are always here to help you</p>
                    <h2>Ask a question</h2>
                </div>
                <div className={classes.col}>
                    <h2>Download our app</h2>
                    <a href="https://play.google.com/store/apps/details?id=com.watchturksapp&hl=en&gl=US">
                        {" "}
                        <img src={googleplay} alt="googleplay"/>
                    </a>
                </div>
            </div>
            <p className={classes.copyWrite}>
                © 2020 Watch Turks - Turkish Series And Movies With English Subtitles{" "}
            </p>
        </div>
    );
};

export default Footer;
