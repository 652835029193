import React, {useContext, useState, useEffect} from 'react';
import classes from './SliderCard.module.css';
import star from './../../img/star.svg';
import save from './../../img/save.svg';
import bookmark from './../../img/bookmark.svg';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {makeReqest} from '../../axios';
import {useNavigate} from 'react-router';
import filledWatchLaterIcon from '../.././img/BookmarkSimple.svg';
import {AuthContext} from '../../context/authContext';
import NotificationFilled from '../../img/notificationFilled.svg';
import {toast} from 'react-toastify';
import axios from 'axios';

const SliderCard = ({seriesItem, isClickAllowed}) => {
	const [isSwiping, setIsSwiping] = useState(false);
	const [isWhishlist, setIsWhishlists] = useState(seriesItem.is_wishlist);
	const handleSwipeStart = () => {
		setIsSwiping(true);
	};

	const handleSwipeEnd = () => {
		setIsSwiping(false);
	};

	const handleLinkClick = (e) => {
		if (isSwiping) {
			e.preventDefault();
		}
		else if (isClickAllowed) {
			navigate(`/seriesdetail/${seriesItem.serie_id}#details`);
		}
	};

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const currentUser = useContext(AuthContext);
	const addToWatchList = useMutation({
		mutationFn: () => {

			const form = new FormData();
			form.set('user_id', currentUser?.currentUser?.body.user?.user_id);
			form.set('serie_id', seriesItem.serie_id);

			return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/wishlist/series/${seriesItem.serie_id}`, form);
		},

		onSuccess: (response) => {
			setIsWhishlists(response.data.body.message.includes('added') ? '1': '0');
			queryClient.invalidateQueries(['home-data']);
			queryClient.invalidateQueries(['watchList']);
			toast.success(response.data.body.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		},
	});

	const addToNotification = useMutation({
		mutationFn: () => {
			const form = new FormData();
			form.set('user_id', currentUser?.currentUser?.body.user?.user_id);
			form.set('serie_id', seriesItem.serie_id);

			return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/user_notifications/${seriesItem.serie_id}`, form);
		},
		onSuccess: (response) => {
			seriesItem.is_notification_on = response.data.body.message.includes('turned on') ? '1': '0';
			queryClient.invalidateQueries(['projects']);
			toast.success(response.data.body.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		},
	});

	const genreText = () => {
		let _text = null;
		if (seriesItem?.genre_title) {
			_text = seriesItem.genre_title;
		}
		else if (seriesItem?.genres) {
			_text = seriesItem.genres.map((genre) => genre.genre_title).join(', ');
		}
		return _text;
	};

	// useEffect(() => {
	//   console.log(isDragging, seriesItem.movie_title)
	// }, [isDragging])

	// console.log(seriesItem);
	return (
		<div className={classes.cardUiTop} onTouchStart={handleSwipeStart} onTouchEnd={handleSwipeEnd} onClick={handleLinkClick}>
			<div className={classes.headerCard}>
				<div className={classes.rating}>
					{(seriesItem?.avg_rating && seriesItem?.avg_rating != "0") && (
						<div>
							<img className={classes.star} src={star} alt="star" />
							<h3>{parseFloat(parseInt(seriesItem?.avg_rating)) || ""}</h3>
						</div>
					)}

					{currentUser.currentUser?.body.user.user_status && (
						<img
							className={classes.save}
							src={isWhishlist == '1' ? filledWatchLaterIcon : save}
							alt="save"
							onClick={(e) => {
								e.stopPropagation();
								addToWatchList.mutate();
							}}
						/>
					)}
					{currentUser.currentUser?.body.user.user_status && (
						<img
							className={classes.bookmark}
							src={seriesItem?.is_notification_on == '1' ? NotificationFilled : bookmark}
							// src={bookmark}
							alt="bookmark"
							onClick={(e) => {
								e.stopPropagation();
								addToNotification.mutate();
							}}
						/>
					)}
				</div>
				<img
					className={classes.imgDefault}
					src={`${process.env.REACT_APP_BASE_URL}/uploads/${seriesItem?.serie_image}`}
					alt="top series"
					// style={{ width: "400px", height: "560px" }}
				/>

				<div className={classes.hoverCard}>
					<div className={classes.details}>
						<h4>
							{seriesItem?.serie_title}

							{/* /{" "}
              {seriesItem?.movie_duration} */}
						</h4>
						<p>{seriesItem?.serie_description}</p>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<h3>{seriesItem?.serie_title}</h3>
				<p>
					{seriesItem?.serie_year} {genreText() && <span style={{position: 'relative', top: '-4px', marginLeft: '10px'}}>.</span>}
					<span style={{marginLeft: '10px'}}>{genreText()}</span>
				</p>
			</div>
		</div>
	);
};
export default SliderCard;
