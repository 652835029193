import classes from './SignInForm.module.css';
import googleLogo from './../../img/googleLogo.svg';
import React, {useContext, useEffect} from 'react';
import eyePass from './../../img/Eyepassword.svg';
import eyeTxt from './../../img/Eyetxt.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {AuthContext} from '../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {useMutation, useQuery} from '@tanstack/react-query';
import ReCAPTCHA from "react-google-recaptcha";

const SignInForm = () => {
    const isTVDevice = () => {
        return navigator.userAgent.includes('TV');
    };
    const [captchaValue, setCaptchaValue] = useState(null);
    const [passwordType, setPasswordType] = useState('password');
    const [passwordInput, setPasswordInput] = useState('');
    const [email, setEmail] = useState('');
    const [gmailToken, setGmailToken] = useState();
    const location = useLocation();
    const {currentUser} = useContext(AuthContext);
    const [FCMToken, setFCMToken] = useState('');
    const [deviceId, setDeviceID] = useState('');
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        if (token) setGmailToken(token);
    }, [gmailToken]);

    const {data, isLoading} = useQuery({
        queryKey: ['googleSignIn'],
        queryFn: () => {
            const form = new FormData();
            form.set('token', gmailToken);
            return axios.post(`https://admin.watchturks.online/api/auth/google-profile`, form);
        },
        enabled: !!gmailToken,
        onSuccess: (data) => {
            localStorage.setItem('user', JSON.stringify(data.data));
            window.location.href = '/';
        },
    });

    // const [enabledGoogleAuth,setEnabledGoogleAuth] = useState(false)
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    };
    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    const {login} = useContext(AuthContext);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await login({ email, inputPassword: passwordInput });
            window.location.href = '/';
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;

                // HTTP 404: Not Found
                if (status === 404) {
                    toast.error('User not found. Please check your credentials.', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        toastId: 'user-not-found-error',
                    });
                }
                // HTTP 400: Bad Request (for other validation errors)
                else if (status === 400) {
                    toast.error('Invalid request. Please try again.', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        toastId: 'bad-request-error',
                    });
                }
                // Other HTTP errors
                else if (status === 500) {
                    let errorMessage = 'Internal server error. Please try again later.';

                    if (data && data.error) {
                        errorMessage = data.error;
                    }

                    toast.error(errorMessage, {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        toastId: 'server-error',
                    });
                }

                else if (data && data.error === 'Invalid Password!') {
                    toast.error('Invalid password. Please try again.', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        toastId: 'invalid-password-error',
                    });
                }
                // Other errors
                else {
                    toast.error(`An error occurred: ${data.message}`, {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        toastId: 'generic-error',
                    });
                }
            } else {
                // Network error or other general errors
                toast.error('An unexpected error occurred. Please try again later.', {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    toastId: 'unexpected-error',
                });
            }
        }
    };


    function handleGoogleSignIn() {

        const googleAuthUrl = `https://admin.watchturks.online/api/auth/google`;

        // Open the Google authentication link in the current tab
        const authWindow = window.open(googleAuthUrl, '_self');
    }


    return (
        <>
            {isLoading && gmailToken ? (
                <div className="flex items-center justify-center m-14">
                    <div className={classes.spinner}></div>
                </div>
            ) : (
                <div className={classes.SignIn}>
                    <h1>Welcome Back</h1>
                    <p>Please enter your details here</p>

                    <button className={classes.googleBtn} onClick={handleGoogleSignIn}>
            <span>
              <img src={googleLogo} alt="googleLogo"/>
              Sign In with Google
            </span>
                    </button>
                    <div className={classes.orRow}>
                        <span className={classes.line}></span>
                        <p>OR</p>
                        <span className={classes.line}></span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.inputStroke}>
              <span>
                <input
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                />
              </span>
                        </div>
                        <div className={classes.pass}>
              <span>
                <input
                    type={passwordType}
                    onChange={handlePasswordChange}
                    value={passwordInput}
                    name="password"
                    placeholder="Password"
                />
                <div>
                  <button type="button" onClick={togglePassword}>
                    {passwordType === 'password' ? (
                        <img src={eyePass} alt="eyePass"/>
                    ) : (
                        <img src={eyeTxt} alt="eyeTxt"/>
                    )}
                  </button>
                </div>

              </span>
                        </div>

                        <div class={classes.container}>
                            <Link to={'/forgotpassword'}>
                                {' '}
                                <p>Forgot password?</p>
                            </Link>
                        </div>
                        <button className={classes.createBtn} type="submit">
                            Log In
                        </button>
                    </form>
                    <span>
            Don’t have an account?{' '}
                        <Link to={'/register'}>
              <p>Register</p>
            </Link>
          </span>
                    <ToastContainer limit={1}/>
                </div>
            )}
        </>
    );
};
export default SignInForm;
