import React, { useContext } from "react";
import star from "../../../img/star.svg";
// import save from "../../../img/save.svg";
// import bookmark from "../../../img/bookmark.svg";
// import filledWatchLaterIcon from "../../../img/BookmarkSimple.svg";
// import NotificationFilled from "../../../img/notificationFilled.svg";
// import classes from "./SeriesCard.module.css";

import { makeReqest } from "../../../axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../../context/authContext";

const SliderCardInfo = ({ serie, infoStyle }) => {
  const queryClient = useQueryClient();
  // const currentUser = useContext(AuthContext);
  // const addToWatchList = useMutation({
  //   mutationFn: (id) => {
  //     return makeReqest.post(`wishlist/series/${serie.serie_id}`);
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(["home-data"]);
  //   },
  // });

  // const addToNotification = useMutation({
  //   mutationFn: () => {
  //     return makeReqest.get(`user_notifications/${serie.serie_id}`);
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(["projects"]);
  //     queryClient.invalidateQueries(["userHistory"]);
  //     queryClient.invalidateQueries(["watchList"]);
  //     queryClient.invalidateQueries(["seriesDetail"]);
  //   },
  // });

  return (
    <>
      <div className="SliderCardInfo" style={infoStyle}>
        <h4>
          {serie.serie_title}
          {/* / {serie.serie_duration} */}
        </h4>
        <div className="CardMeta">
          <div className="CardRating">
            <img className="star" src={star} alt="star" />
            <h3>{parseFloat(parseInt(serie?.avg_rating)) || ''}</h3>

            {/* {currentUser.currentUser?.body.user.user_status && (
              <img
                className={classes.NotificationDesktop}
                // style={{width:'48px',height:'48px'}}
                src={serie.is_notification_on ? NotificationFilled : bookmark}
                // src={bookmark}
                style={{ cursor: "pointer" }}
                alt="bookmark"
                onClick={(e) => {
                  e.stopPropagation();
                  addToNotification.mutate();
                }}
              />
            )}

            {currentUser.currentUser?.body.user.user_status && (
              <img
                className={classes.save}
                src={serie?.is_wishlist ? filledWatchLaterIcon : save}
                alt="save"
                onClick={(e) => {
                  e.stopPropagation();
                  addToWatchList.mutate(serie.serie_id);
                }}
              />
            )} */}
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
            <circle cx="1.5" cy="2" r="1.5" fill="white" />
          </svg>
          <div className="CardGenre">{serie.genre_title}</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
            <circle cx="1.5" cy="2" r="1.5" fill="white" />
          </svg>
          <div className="CardYear">{serie.serie_year}</div>
        </div>
        <div className="CardDesc">{serie.serie_description}</div>
      </div>
    </>
  );
};

export default SliderCardInfo;
