import React, {useContext} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Next from './../../../StaticPic/Next.png';
import play from './../../../img/Play.svg';
import classes from './LatestEpisode.module.css';
import {useState} from 'react';
import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useNavigate} from 'react-router';
import Spinner from '../../spinner/Spinner';
import {AuthContext} from '../../../context/authContext';

let slidesToShow = 5;
const PreviousBtn = (props) => {
	const {className, onClick, currentSlide} = props;
	return (
		<>
			{currentSlide !== 0 && (
				<div className={className} onClick={onClick} style={{left: '-60px'}}>
					{/* <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} /> */}
					<img className={classes.carouselPrev} src={Next} alt="prev"/>
				</div>
			)}
		</>
	);
};
const NextBtn = (props) => {
	const {className, onClick, slideCount, currentSlide} = props;
	return (
		<>
			{currentSlide !== slideCount - slidesToShow && (
				<div className={className} onClick={onClick}>
					{/* <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} /> */}
					<img className={classes.carouselNext} src={Next} alt="next"/>
				</div>
			)}
		</>
	);
};

const LatestEpisode = () => {
	const [isClickAllowed, setIsClickAllowed] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);
	const updateWidth = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', updateWidth);
		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	const carouselProperties = {
		prevArrow: <PreviousBtn/>,
		nextArrow: <NextBtn/>,
		slidesToShow: slidesToShow,
		slidesToScroll: 2,
		infinite: false,
		// slidesToScroll={3}
		responsive: [
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1, // Mobilde sadece 1 slaytı göster
					centerMode: true,
				},
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2, // Tablet boyutunda 2 slaytı göster
					centerMode: false,
				},
			},
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					centerMode: false,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 2050,
				settings: {
					slidesToShow: 3,
					centerMode: false,
					slidesToScroll: 1,
				},
			},
		],
		beforeChange: (current, next) => {
			setIsClickAllowed(false);
		},
		afterChange: (current) => {
			setIsClickAllowed(true);
		},
	};

	if (width <= 426) {
		slidesToShow = 1;
	}
	else if (width > 426 && width <= 769) {
		slidesToShow = 2;
	}
	else if (width > 769 && width <= 1025) {
		slidesToShow = 2;
	}
	else if (width > 1025 && width <= 1920) {
		slidesToShow = 3;
	}
	else {
		slidesToShow = 3;
	}

	const currentUser = useContext(AuthContext);
	const userId = currentUser?.body?.user?.user_id ? '?userId=' + currentUser?.body?.user?.user_id : '';

	const {data, isLoading} = useQuery({
		queryKey: ['home-data'],
		queryFn: () => {
			return axios.get(`https://admin.watchturks.online/api/home` + userId);
		},
	});
	// console.log(data?.data.body.canPlay);
	return (
		<div className={classes.carousel}>
			<h2>Latest episodes</h2>
			{isLoading ? (
				<Spinner/>
			) : (
				<Slider {...carouselProperties}>
					{data?.data.body.latestEpisodes.map((item) => (
						<Card canPlay={data?.data.body.canPlay} latestEpisode={item} key={item.episode_id} isClickAllowed={isClickAllowed}/>
					))}
				</Slider>
			)}
		</div>
	);
};

const Card = ({latestEpisode, canPlay, isClickAllowed}) => {
	const currentUser = useContext(AuthContext);
	const navigate = useNavigate();
	// console.log(latestEpisode)
	return (
		<div
			className={classes.card}
			// onClick={() => {
			//   navigate(`watchEpisodes/${latestEpisode.episode_id}`);
			// }}
			onClick={(e) => {
				if (!currentUser.currentUser) return navigate('/signin');
				else if (isClickAllowed)
					if (canPlay) {
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth',
						})
						navigate(`/watchEpisodes/${latestEpisode.episode_id}`)
					}
					else {
						navigate('/choosepayment');
					}
				e.stopPropagation();
			}}
			style={{cursor: 'pointer'}}
		>
			<img className={classes.playSeriesEp} src={play} alt="play"/>
			<div className={classes.posterSeries}>
				<img
					className={classes.multiImage}
					src={`${latestEpisode.episode_image}`}
					alt=""
					style={{
						width: '100%',
						borderRadius: '20px',
					}}
				/>
			</div>

			<h2>{latestEpisode.serie_title}</h2>
			<p>{`${latestEpisode.season_title} ${latestEpisode.episode_title}`}</p>
			{/* <p>Season 1 Episode 3</p> */}
		</div>
	);
};

export default LatestEpisode;
