import React from "react";
import classes from "./ForgotPass.module.css";
import loginGif from "./../../img/loginGif.gif";
import ForgotForm from "./ForgotForm";
import logo from "./../../img/Logo.svg";
import { useNavigate } from "react-router";

const ForgotPass = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.ForgotTop}>
      <div className={classes.row}>
        <div className={classes.left}>
          <div
            className={classes.logo}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={logo} alt="logo" />
            <h2>WatchTurks </h2>
          </div>
          <ForgotForm />
        </div>
        <div className={classes.right}>
          <img src={loginGif} alt="Gif" />
        </div>
      </div>
    </div>
  );
};
export default ForgotPass;
