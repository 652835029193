import React, {useContext, useEffect, useState} from 'react';
import Filter from '../Filter/Filter';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import classes from './AllMovies.module.css';
import MovieCard from './MovieCard';
import axios from 'axios';
import Spinner from '../spinner/Spinner';
import NoResult from '../Search/NoResult';
import {useLocation} from 'react-router-dom';
import {AuthContext} from '../../context/authContext';

const AllMovies = () => {
	const [query, setQuery] = useState('');
	const [allMovies, setAllMovies] = useState([]);
	const [filteredData, setFilteredData] = useState({});
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const searchQuery = searchParams.get('search');
	const {currentUser} = useContext(AuthContext);

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		setPage(1); // Sayfayı 1'e sıfırla
		fetchData();
	}, [filteredData, searchQuery]); // searchQuery'i izle

	useEffect(() => {
		fetchData();
	}, [page, query]);

	const fetchData = async () => {
		try {
			setLoading(true);
			const queryParams = [];

			if (filteredData && (filteredData.genre || filteredData.year || filteredData.sortby)) {

				if (filteredData.genre) {
					queryParams.push(`genre=${filteredData.genre}`);
				}
				if (filteredData.year) {
					queryParams.push(`year=${filteredData.year}`);
				}
				if (filteredData.sortby) {
					queryParams.push(`sortby=${filteredData.sortby || ''}`);
				}
			}

			// Arama sorgusu varsa sorguya ekle
			if (query || searchQuery) {
				queryParams.push(`search=${query || searchQuery}`);
			}

			// Sayfa numarası ve limiti sorguya ekle
			queryParams.push(`page=${page}`);

			if (currentUser?.body.user?.user_id) {
				// Sayfa numarası ve limiti sorguya ekle
				queryParams.push(`user_id=${currentUser?.body.user?.user_id}`);
			}

			const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

			const response = await axios.get(
				`https://admin.watchturks.online/api/movies/filters${queryString}`
			);

			setAllMovies(response.data.body.movies);
			setTotalPages(response.data.body.total_pages);
			setLoading(false)
		}
		catch (error) {
			console.error('Veri çekme hatası:', error);
			setLoading(false)
		}
	};

	// const {
	// 	      data: infinitData,
	// 	      fetchNextPage,
	// 	      hasNextPage,
	// 	      isLoading,
	//       } = useInfiniteQuery({
	// 	queryKey: ['loadMovies'],
	// 	queryFn: ({pageParam = 0}) => {
	// 		return axios.get(
	// 			`https://admin.watchturks.online/api/movies?page=${pageParam}&limit=16&sortBy=movie_year&sortOrder=desc`,
	// 			{userId: JSON.parse(localStorage.getItem('user'))?.body.user.user_id}
	// 		);
	// 	},
	// 	refetchOnWindowFocus: false,
	// 	getNextPageParam: (lastPage, pages) => {
	// 		if (lastPage.data.body.movies.length > 0) {
	// 			return loadMore;
	// 		}
	// 		else {
	// 			return undefined;
	// 		}
	// 	},
	// });
	// const handleSearch = (e) => {
	// 	console.log(e)
	// 	setQuery(e);
	// };
	//
	// // const {data: searchResults, isFetching} = useQuery({
	// // 	queryKey: ['searchmovies', query],
	// // 	queryFn: () => {
	// // 		return makeReqest.get(
	// // 			`${process.env.REACT_APP_BASE_URL}/api/movies/search?q=${query}`
	// // 		);
	// // 	},
	// // 	enabled: !!query,
	// // });
	// const {data: filteredMovies, isLoading: filterIsLoading} = useQuery({
	// 	queryKey: ['filteredMovies', filteredData],
	// 	queryFn: () => {
	// 		const formdata = new FormData();
	// 		formdata.set('genre', filteredData.genre);
	// 		formdata.set('year', filteredData.year);
	// 		return axios.post(
	// 			`https://admin.watchturks.online/api/movies/filters?page=${loadMore}`, formdata
	// 		);
	// 	},
	// 	enabled: !!filteredData,
	// });

	const handleSearch = (e) => {
		setQuery(e);
		//setPage(1);
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	const getVisiblePageNumbers = () => {
		const numbers = [];
		let startPage = Math.max(1, page - 2);
		let endPage = Math.min(totalPages, page + 2);

		if (totalPages <= 5) {
			startPage = 1;
			endPage = totalPages;
		}
		else {
			if (page <= 2) {
				startPage = 1;
				endPage = 5;
			}

			if (page >= totalPages - 1) {
				startPage = totalPages - 4;
				endPage = totalPages;
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			numbers.push(i);
		}

		return numbers;
	};

	const visiblePageNumbers = getVisiblePageNumbers();

	return (
		<div className={classes.allMoviesTop}>
			<div className={classes.allmoviesInner}>
				<Header/>
				<div className={classes.moviesList}>
					<Filter
						handleFilterData={setFilteredData}
						handleSearch={handleSearch}
					/>
					<h2>All Movies</h2>
					<div className={classes.row}>
						{ loading ? (
							<Spinner/>
						) : (
							allMovies.length === 0 ? (
								<NoResult />
							) : (
							allMovies.map((movie) => (
								<MovieCard movie={movie} key={movie.movie_id}/>
							))
							)
						)}
					</div>
					{/*		<div className={classes.row}>*/}
					{/*			{*/}
					{/*				// (filteredMovies?.data.body.movies.length === 0 ||*/}
					{/*				//   !filteredMovies) &&*/}
					{/*				isLoading || isFetching || filterIsLoading ? (*/}
					{/*					<Spinner/>*/}
					{/*				) : (*/}
					{/*					infinitData?.pages.map((page) => {*/}
					{/*						const moviesToRender =*/}
					{/*							      !query && filteredMovies?.data.body.movies.length === 0*/}
					{/*								      ? page*/}
					{/*								      : query && filteredMovies?.data.body.movies.length === 0*/}
					{/*									      ? searchResults*/}
					{/*									      : filteredMovies;*/}
					{/*						return (*/}
					{/*							moviesToRender &&*/}
					{/*							moviesToRender.data.body.movies.map((movie) => (*/}
					{/*								<MovieCard movie={movie} key={movie.movie_id}/>*/}
					{/*							))*/}
					{/*						);*/}
					{/*					})*/}
					{/*				)*/}
					{/*			}*/}
					{/*			{searchResults?.data.body.movies.length <= 0 && query && (*/}
					{/*				<NoResult/>*/}
					{/*			)}*/}
					{/*			*/}
					{/*			*/}
					{/*			/!* {filteredMovies?.data.body.movies.map((movie) => (*/}
					{/*  <MovieCard movie={movie} />*/}
					{/*))} *!/*/}
					{/*		</div>*/}
					{allMovies.length > 0 && (
						<div className={classes.pagination}>
							<button onClick={() => handlePageChange(1)} className={`pagination-first ${page === 1 ? 'd-noned' : ''}`} disabled={page === 1}>
								&laquo;&laquo;
							</button>

							<button onClick={() => handlePageChange(page - 1)} className={page === 1 ? 'd-noned' : ''} disabled={page === 1}>
								&laquo;
							</button>

							{page > 3 && <span className={`pagination-dot`}>...</span>}
							{visiblePageNumbers.map(num => (
								<button
									key={num}
									onClick={() => handlePageChange(num)}
									className={page === num ? classes.active : ''}
								>
									{num}
								</button>
							))}
							{page < totalPages - 2 && <span className={`pagination-dot`}>...</span>}

							<button onClick={() => handlePageChange(page + 1)} className={page === totalPages ? 'd-noned' : ''} disabled={page === totalPages}>
								&raquo;
							</button>

							<button onClick={() => handlePageChange(totalPages)} className={`pagination-last ${page === totalPages ? 'd-noned' : ''}`} disabled={page === totalPages}>
								&raquo;&raquo;
							</button>
						</div>
					)}
					{/*{infinitData?.pages.length > 0 && !query && (*/}
					{/*	<button*/}
					{/*		className={classes.loadMoreBtn}*/}
					{/*		onClick={() => {*/}
					{/*			fetchNextPage();*/}
					{/*			setLoadMore((prev) => prev + 1);*/}
					{/*		}}*/}
					{/*		disabled={!hasNextPage}*/}
					{/*	>*/}
					{/*		<span>Show more</span>*/}
					{/*	</button>*/}
					{/*)}*/}
				</div>

				<Footer/>
			</div>
		</div>
	);
};
export default AllMovies;
