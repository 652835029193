import React from "react";
import {Outlet} from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import classes from "./Services.module.css";

const Services = () => {
    return (
        <div className={classes.homeTop}>
            <div className={classes.homeInner}>
                <Header/>
                <div className={classes.serviceHeader}>
                    <h1>Watch Turks - Our Services</h1>
                    <br></br>
                    <p>WatchTurks is an innovative captioning platform crafted by professionals. Our team of skilled
                        editors meticulously produces subtitles for a diverse range of movies, videos, and TV series.
                        The subtitle content we generate is publicly accessible on popular web platforms such as
                        YouTube, Vimeo, and more.</p>
                </div>
                <div className={classes.serviceCards}>
                    {/* Service Card 1 */}
                    <div className={classes.serviceCard}>
                        <img src="cc.png" alt="Service Icon 1"/>
                        <h2>English Subtitle Production
                        </h2>
                        <p>We expertly craft English subtitles for Turkish series for an international audience.
                        </p>
                    </div>
                    {/* Service Card 2 */}
                    <div className={classes.serviceCard}>
                        <img src="cc.png" alt="Service Icon 2"/>
                        <h2>Multilingual Subtitle Translation
                        </h2>
                        <p>Our team specializes in translating them into various languages to connect with viewers worldwide.
                        </p>
                    </div>
                    {/* Service Card 3 */}
                    <div className={classes.serviceCard}>
                        <img src="cc.png" alt="Service Icon 3"/>
                        <h2>Custom Video Captions
                        </h2>
                        <p>We provide personalized captioning services for your requests.</p>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Services;
