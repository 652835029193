import React from "react";

const DateFormater = ({ date }) => {
  const newDate = new Date(date);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = newDate.toLocaleDateString("en-US", options);

  return <p>{formattedDate}</p>;
};

export default DateFormater;
