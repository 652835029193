import DateFormater from "../../components/DateFormater";
import React, { createContext, useContext, useState } from "react";
import classes from "./ProfileMain.module.css";
import clock from "./../../img/Clock.svg";
import watchLater from "./../../img/watchlater.svg";
import PencilSimple from "./../../img/PencilSimple.svg";
import MonitorPlay from "./../../img/MonitorPlay.svg";
import ShieldCheck from "./../../img/ShieldCheck.svg";
import ListBullets from "./../../img/ListBullets.svg";
import ChatCircleDots from "./../../img/ChatCircleDots.svg";
import SignOut from "./../../img/SignOut.svg";
import info from "./../../img/Info.svg";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { AuthContext } from "../../context/authContext";
import { useMutation } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import logo from "./../../img/Logo.svg";
export const leftMenu = createContext();

const ProfileMain = () => {
  // const [showLeftSidebar, setIsShowLeftSideBar] = useState(false);

  // const showLeftSidebarHandler = () => {
  //   setIsShowLeftSideBar(!showLeftSidebar);
  //   alert("hello");
  // };

  const [sidebarLeft, setSidebarLeft] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const showSidebarLeft = () => {
    setSidebarLeft(!sidebarLeft);
  };

  const hideMenuLeft = () => {
    setSidebarLeft(false);
  };

  const deleteFcm = useMutation((id) =>
    makeReqest.delete(`/fcm`, {
      data: { device_id: localStorage.getItem("device_id") },
    })
  );

  const logoutHandler = () => {
    localStorage.removeItem("user");
    deleteFcm.mutate();
    localStorage.removeItem("fcm_token");
    setCurrentUser(null);
  };

  return (
    <div className={classes.ProfileMain}>
      <div className={classes.bg}>
        <Header />
        <div className={classes.rowProfile}>
          <div className={sidebarLeft ? `${classes.tablist} ${classes.tablistActive}` : `${classes.tablist}`}>
            <div className={classes.headerTablist}>
              <div className={classes.userProfile}>
                {!currentUser?.body.user.image_url ? <img className={classes.userImg} src={logo} alt="userProfile" /> : <img className={classes.userImg} src={currentUser?.body.user.image_url.startsWith("https") ? currentUser?.body.user.image_url : `${process.env.REACT_APP_BASE_URL}/uploads/${currentUser?.body.user.image_url}`} alt="userProfile" />}

                <span>
                  <h3>{!currentUser?.body.user.user_name ? "Watch Turks" : currentUser?.body.user.user_name}</h3>
                  <p>{!currentUser?.body.user.user_email ? "@watchturks" : currentUser?.body.user.user_email}</p>
                </span>
              </div>
              {!currentUser?.body.user || !currentUser?.body.user.user_status ? (
                <NavLink to="/choosepayment" style={{ color: "white" }}>
                  Choose a plan
                </NavLink>
              ) : (
                <>
                  <h3>Member since</h3>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ width: "120px" }}>
                      <DateFormater date={currentUser?.body.user.user_created} />
                    </p>
                    <p style={{ width: "120px" }}>{currentUser?.body.user.plan_id && currentUser?.body.user.remaining ? `/ ${currentUser?.body.user.remaining} days left` : "Trial"}</p>
                  </div>
                </>
              )}
            </div>

            <div className={classes.tablistmain}>
              {currentUser && (
                <>
                  <h3>Media</h3>
                  <ul>
                    <NavLink to="watch-later" onClick={showSidebarLeft}>
                      <li onClick={hideMenuLeft}>
                        <img src={watchLater} alt="watchLater" /> Favorites
                      </li>
                    </NavLink>
                    <br />
                    <NavLink to="history" onClick={hideMenuLeft}>
                      {" "}
                      <li className={classes.last}>
                        <img src={clock} alt="clock" /> History
                      </li>
                    </NavLink>
                  </ul>
                  <h3 className={classes.account}>Accounts</h3>
                  <ul>
                    {!currentUser ? (
                      <NavLink to="signin" onClick={hideMenuLeft}>
                        {" "}
                        <li>
                          <img src={PencilSimple} alt="PencilSimple" /> Edit Profile
                        </li>
                      </NavLink>
                    ) : (
                      <NavLink to="edit-profile" onClick={hideMenuLeft}>
                        {" "}
                        <li>
                          <img src={PencilSimple} alt="PencilSimple" /> Edit Profile
                        </li>
                      </NavLink>
                    )}
                    <br />
                    <NavLink to="subscription" onClick={hideMenuLeft}>
                      {" "}
                      <li className={classes.last}>
                        <img src={MonitorPlay} alt="MonitorPlay" /> Subscription
                      </li>
                    </NavLink>
                  </ul>
                </>
              )}

              <h3 className={classes.support}>Support</h3>
              <ul>
                <NavLink to="faq" onClick={hideMenuLeft}>
                  <li>
                    <img src={info} alt="info" /> FAQs
                  </li>
                </NavLink>
                <br />
                <NavLink to="privacy-policy" onClick={hideMenuLeft}>
                  <li>
                    <img src={ShieldCheck} alt="ShieldCheck" /> Privacy Policy
                  </li>
                </NavLink>
                <br />
                <NavLink to="copyright-policy" onClick={hideMenuLeft}>
                  <li>
                    <img src={ShieldCheck} alt="ShieldCheck" /> Copyright Policy
                  </li>
                </NavLink>
                <br />
                <NavLink to="term-condition" onClick={hideMenuLeft}>
                  <li>
                    <img src={ListBullets} alt="ListBullets" /> Terms & Conditions
                  </li>
                </NavLink>
                <br />
                <NavLink to="contact" onClick={hideMenuLeft}>
                  <li className={classes.last}>
                    <img src={ChatCircleDots} alt="ChatCircleDots" /> Contact Us
                  </li>
                </NavLink>
                {currentUser && (
                  <NavLink to="/signin" className={classes.logout} onClick={logoutHandler} style={{ width: "100%" }}>
                    <li>
                      {" "}
                      <img src={SignOut} alt="SignOut" /> Log Out
                    </li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
          <div className={sidebarLeft ? `${classes.mainContent} ${classes.mainContentAtive}` : `${classes.mainContent}`}>
            <leftMenu.Provider value={showSidebarLeft}>
              <Outlet />
            </leftMenu.Provider>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default ProfileMain;
