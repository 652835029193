import React, {useContext, useState, useEffect} from "react";
import AccordionItem from "./Accordion";
import classes from "./Faqs.module.css";
import faq from "./../../../img/Info.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import {leftMenu} from "../ProfileMain";

const Faqs = () => {
    const ctxContact = useContext(leftMenu);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        // API'den veri çekme
        fetch("https://admin.watchturks.online/api/faq")
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "success") {
                    setFaqs(data.data);
                } else {
                    console.error("API'den veri alınamadı.");
                }
            })
            .catch((error) => {
                console.error("API'ye erişim sırasında bir hata oluştu:", error);
            });
    }, []);

    const showSidebar = () => {
        ctxContact();
    };
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    };

    return (
        <div className={classes.faqsTop}>
            <div>
                <div className={classes.headerFaqs}>
                    <img
                        className={classes.caretLeft}
                        onClick={showSidebar}
                        src={CaretLeft}
                        alt="CaretLeft"
                    />
                    <div className={classes.row}>
                        <img src={faq} alt="faq"/>
                        <h1>FAQs</h1>
                    </div>
                    <p>Here are some of the most frequently asked questions</p>
                </div>
                {faqs.map((faq, index) => {
                    return (
                        <AccordionItem
                            key={index}
                            active={active}
                            handleToggle={handleToggle}
                            faq={faq}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Faqs;
