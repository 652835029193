import React from "react";
import {Outlet} from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import classes from "./Home.module.css";

const Home = () => {
    return (
        <div className={classes.homeTop}>
            <div className={classes.homeInner}>
                <Header/>
                <Outlet/>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;
