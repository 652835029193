import Cookies from 'js-cookie';
import {createContext, useEffect, useState} from 'react';
import axios from 'axios';
import React from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
	const [currentUser, setCurrentUser] = useState(
		JSON.parse(localStorage.getItem('user')) || null
	);

	const login = async (inputs) => {
		const form = new FormData();
		form.set('email', inputs?.email);
		form.set('inputPassword', inputs?.inputPassword);
		const user = await axios.post(`https://admin.watchturks.online/api/auth/token`, form);
		setCurrentUser(user.data);

		if (user?.data?.body?.token) {
			Cookies.set('token', user.data.body.token, {expires: 24});
		}
	};

	useEffect(() => {
		localStorage.setItem('user', JSON.stringify(currentUser));
	}, [currentUser]);

	return (
		<AuthContext.Provider value={{currentUser, setCurrentUser, login}}>
			{children}
		</AuthContext.Provider>
	);
};
