import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const SliderMoviesCard = ({ movie, order, isClickAllowed }) => {
  const navigate = useNavigate();

  const [isSwiping, setIsSwiping] = useState(false);
  const handleSwipeStart = () => {
    setIsSwiping(true);
  };

  const handleSwipeEnd = () => {
    setIsSwiping(false);
  };

  const handleLinkClick = (e) => {
    if (isSwiping) {
      e.preventDefault(); // Swipe işlemi sırasında tıklamayı engelle
    } else if (isClickAllowed) {
  
      navigate(`/seriesdetail/${movie.serie_id}#details`);
    }
  };

  return (
    <>
      <div className="topTenNum">{order + 1}</div>
      <div className="SliderMovieCard" onTouchStart={handleSwipeStart} onTouchEnd={handleSwipeEnd} onClick={handleLinkClick}>
        <div className="CardImage">
          <img
            src={`${process.env.REACT_APP_BASE_URL}/uploads/${movie.serie_image}`}
            alt="Movie"
            // style={{ width: "400px", height: "560px" }}
          />
        </div>
      </div>
    </>
  );
};

export default SliderMoviesCard;
