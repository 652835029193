import classes from "./Spinner.module.css";
const Spinner = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
      }}
    >
      <div className={classes.spinner}></div>
    </div>
  );
};

export default Spinner;
