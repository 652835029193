import React, {useContext} from "react";
import classes from "./TermCondition.module.css";
import ListBullets from "./../../../img/ListBullets.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import {leftMenu} from "../ProfileMain";

const TermCondition = () => {
    const ctxTermCondition = useContext(leftMenu);

    const showSidebar = () => {
        ctxTermCondition();
    };
    return (
        <div className={classes.termConditionTop}>
            <div className={classes.termHeader}>
                <img
                    className={classes.CaretLeft}
                    onClick={showSidebar}
                    src={CaretLeft}
                    alt="CaretLeft"
                />
                <img
                    className={classes.ListBullets}
                    src={ListBullets}
                    alt="ListBullets"
                />
                <h1>Terms & Conditions</h1>
            </div>
            <p className={classes.mb16}>
                Watch Turks is an innovative captioning platform crafted by professionals. Our team of skilled editors
                meticulously produces subtitles for a diverse range of movies, videos, and TV series. The subtitle
                content we generate is publicly accessible on popular web platforms such as YouTube, Vimeo, and more.
                How do we create subtitles? We do not own any videos! All rights belong to the owner of the videos. We
                only use those public videos to create English subtitles.
            </p>
            <h2>Subscription & Payments</h2>
            <p className={classes.mb16}>
                We offer a range of subscription plans tailored to suit your needs.
            </p>
            <h2>Payment Options:
            </h2>
            <p className={classes.mb16}>
                We offer flexible payment options to make the subscription process convenient for you. <br></br>-
                Credit/Debit
                Cards: We accept all major credit and debit cards, ensuring a secure and straightforward payment
                experience. <br></br>
                - PayPal: For added convenience, we also support PayPal payments, allowing you to
                use your
                PayPal balance or linked payment methods. After the payment, you should wait at least 15 minutes to
                activate your plan. If you make another payment during this approving period, we can’t refund the money
                since it is made willingly by you.
            </p>
            <h2>Automatic Renewal:</h2>
            <p className={classes.mb24}>
                To avoid any service disruptions, all subscriptions are set to renew at the end of the billing cycle
                automatically. You will receive a notification before each renewal, giving you the option to make
                changes or cancel if needed. P.S: If you don’t change or cancel your plan before the cycling date, we
                can’t refund the payment.
            </p>
            <h2>Managing Subscriptions:</h2>
            <p className={classes.mb24}>
                We've made it easy for you to manage your subscriptions. Through your account dashboard, you can upgrade
                or downgrade your plan, update payment methods, and view your billing history.

            </p>
            <h2>Cancellations and Refunds:</h2>
            <p className={classes.mb24}>
                You can cancel your subscription at any time before the next billing cycle. We do not offer refunds for
                partial usage during the billing period, but your subscription will remain active until the end of the
                paid period.

            </p>
            <br/>
            <h2>Website Terms of Use</h2>
            <p class="s5"><span class="s8">Acceptance of the Terms of Use</span></p>
            <p class="s5"><span class="s3">These terms of use are entered into by and between You and </span><span
                class="s3">Watch Turks</span><span class="s3"> ("Company," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use of https://</span><span
                class="s3">watchturks.online</span><span class="s3">, including any content, functionality and services offered on or through https://watchturks.online</span>
                <span class="s3">(the "Website"), whether as a guest or a registered user.</span></p>
            <p class="s5"><span class="s3">Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at https://watchturks.online/</span><span
                class="s3">privacy-policy</span><span class="s3">, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</span>
            </p>
            <p class="s5"><span class="s3">This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company and meet </span><span
                class="s3">all of</span><span
                class="s3"> the foregoing eligibility requirements. If you do not meet </span><span
                class="s3">all of</span><span
                class="s3">these requirements, you must not access or use the Website.</span></p>
            <p class="s5"><span class="s8">Changes to the Terms of Use</span></p>
            <p class="s5"><span class="s3">We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Website.</span>
            </p>
            <p class="s5"><span class="s3">Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access this </span><span
                class="s3">Website</span><span
                class="s3"> so you are aware of any changes, as they are binding on you.</span></p>
            <p class="s5"><span class="s8">Accessing the Website and Account Security</span></p>
            <p class="s5"><span class="s3">We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire </span><span
                class="s3">Website</span><span class="s3">, to users, including registered users.</span></p>
            <p class="s5"><span class="s3">You are responsible for both:</span></p>
            <p class="s5"><span
                class="s3">Making all arrangements necessary for you to have access to the Website.</span></p>
            <p class="s5"><span class="s3">Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</span>
            </p>
            <p class="s5"><span class="s3">To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, </span><span
                class="s3">current</span><span class="s3"> and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, </span><span
                class="s3">through the use of</span><span class="s3"> any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</span>
            </p>
            <p class="s5"><span class="s3">If you choose, or are provided with, a </span><span
                class="s3">user name</span><span class="s3">, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your </span><span
                class="s3">user name</span><span class="s3">, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your </span><span
                class="s3">user name</span><span class="s3"> or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use </span><span
                class="s3">particular caution</span><span class="s3"> when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</span>
            </p>
            <p class="s5"><span class="s3">We have the right to disable any </span><span
                class="s3">user name</span><span class="s3">, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s8">Intellectual Property Rights</span></p>
            <p class="s5"><span class="s3">The Website and its entire contents, </span><span
                class="s3">features</span><span class="s3"> and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</span>
            </p>
            <p class="s5"><span class="s3">These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our </span><span
                class="s3">Website</span><span class="s3">, except as follows:</span></p>
            <p class="s5"><span class="s3">Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</span>
            </p>
            <p class="s5"><span class="s3">You may store files that are automatically cached by your Web browser for display enhancement purposes.</span>
            </p>
            <p class="s5"><span class="s3">You may print one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, </span><span
                class="s3">publication</span><span class="s3"> or distribution.</span></p>
            <p class="s5"><span class="s3">If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</span>
            </p>
            <p class="s5"><span class="s3">You must not:</span></p>
            <p class="s5"><span class="s3">Modify copies of any materials from this site.</span></p>
            <p class="s5"><span class="s3">Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</span>
            </p>
            <p class="s5"><span class="s3">Delete or alter any copyright, </span><span class="s3">trademark</span><span
                class="s3"> or other proprietary rights notices from copies of materials from this site.</span></p>
            <p class="s5"><span class="s3">You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</span>
            </p>
            <p class="s5"><span class="s3">If you wish to make any use of material on the Website other than that set out in this section, please address your request to: </span><span
                class="s3">admin@watchturks.com</span></p>
            <p class="s5"><span class="s3">If you print, copy, modify, </span><span class="s3">download</span><span
                class="s3"> or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, </span><span
                class="s3">title</span><span class="s3"> or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, </span><span
                class="s3">trademark</span><span class="s3"> and other laws.</span></p>
            <p class="s5"><span class="s8">Trademarks</span></p>
            <p class="s5"><span class="s3">The Company name, the terms </span><span class="s3">WATCH TURKS</span><span
                class="s3">, the Company logo and all related names,</span><span class="s3">logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.</span>
            </p>
            <p class="s5"><span class="s8">Prohibited Uses</span></p>
            <p class="s5"><span class="s3">You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</span>
            </p>
            <p class="s5"><span class="s3">In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</span>
            </p>
            <p class="s5"><span class="s3">For the purpose of</span><span class="s3"> exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</span>
            </p>
            <p class="s5"><span class="s3">To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</span>
            </p>
            <p class="s5"><span class="s3">To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).</span>
            </p>
            <p class="s5"><span class="s3">To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</span>
            </p>
            <p class="s5"><span class="s3">Additionally, you agree not to:</span></p>
            <p class="s5"><span class="s3">Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website.</span>
            </p>
            <p class="s5"><span class="s3">Use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</span>
            </p>
            <p class="s5"><span class="s3">Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</span>
            </p>
            <p class="s5"><span class="s3">Use any device, software or routine that interferes with the proper working of the Website.</span>
            </p>
            <p class="s5"><span class="s3">Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</span>
            </p>
            <p class="s5"><span class="s3">Attempt to gain unauthorized access to, interfere with, </span><span
                class="s3">damage</span><span class="s3"> or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</span>
            </p>
            <p class="s5"><span class="s3">Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</span>
            </p>
            <p class="s5"><span class="s3">Otherwise attempt to interfere with the proper working of the Website.</span>
            </p>
            <p class="s5"><span class="s8">User Contributions</span></p>
            <p class="s5"><span class="s3">The Website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, </span><span
                class="s3">display</span><span class="s3"> or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Website.</span>
            </p>
            <p class="s5"><span class="s3">All User Contributions must comply with the Content Standards set out in these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">Any User Contribution you post to the site will be considered non-confidential and non- proprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</span>
            </p>
            <p class="s5"><span class="s3">You represent and warrant that:</span></p>
            <p class="s5"><span class="s3">You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, </span><span
                class="s3">successors</span><span class="s3"> and assigns.</span></p>
            <p class="s5"><span class="s3">All of</span><span class="s3"> your User Contributions do and will comply with these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, </span><span
                class="s3">accuracy</span><span class="s3"> and appropriateness.</span></p>
            <p class="s5"><span class="s3">We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Website.</span>
            </p>
            <p class="s5"><span class="s8">Monitoring and Enforcement; Termination</span></p>
            <p class="s5"><span class="s3">We have the right to:</span></p>
            <p class="s5"><span class="s3">Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</span>
            </p>
            <p class="s5"><span class="s3">Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public or could create liability for the Company.</span>
            </p>
            <p class="s5"><span class="s3">Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</span>
            </p>
            <p class="s5"><span class="s3">Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website.</span>
            </p>
            <p class="s5"><span class="s3">Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span>
            </p>
            <p class="s5"><span class="s3">However, we do not undertake to review all material before it is posted on the </span><span
                class="s3">Website, and</span><span class="s3">cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, </span><span
                class="s3">communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or </span><span
                class="s3">nonperformance</span><span class="s3"> of the activities described in this section.</span>
            </p>
            <p class="s5"><span class="s8">Content Standards</span></p>
            <p class="s5"><span class="s3">These content standards apply to </span><span
                class="s3">any and all</span><span class="s3"> User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international </span><span
                class="s3">laws</span><span class="s3"> and regulations. Without limiting the foregoing, User Contributions must not:</span>
            </p>
            <p class="s5"><span class="s3">Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, </span><span
                class="s3">inflammatory</span><span class="s3"> or otherwise objectionable.</span></p>
            <p class="s5"><span class="s3">Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual </span><span
                class="s3">orientation</span><span class="s3"> or age.</span></p>
            <p class="s5"><span class="s3">Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</span>
            </p>
            <p class="s5"><span class="s3">Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may </span><span
                class="s3">be in conflict with</span><span class="s3"> these Terms of Use and our Privacy Policy.</span>
            </p>
            <p class="s5"><span class="s3">Be likely to deceive any person.</span></p>
            <p class="s5"><span class="s3">Promote any illegal activity, or advocate, promote or assist any unlawful act.</span>
            </p>
            <p class="s5"><span class="s3">Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, </span><span
                class="s3">alarm</span><span class="s3">or annoy any other person.</span></p>
            <p class="s5"><span class="s3">Impersonate any </span><span class="s3">person, or</span><span class="s3"> misrepresent your identity or affiliation with any person or organization.</span>
            </p>
            <p class="s5"><span class="s3">Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, </span><span
                class="s3">barter</span><span class="s3"> or advertising.</span></p>
            <p class="s5"><span class="s3">Give the impression that they emanate from or are endorsed by us or any other person or </span><span
                class="s3">entity, if</span><span class="s3"> this is not the case.</span></p>
            <p class="s5"><span class="s8">Copyright Infringement</span></p>
            <p class="s5"><span class="s3">If you believe that any User Contributions violate your copyright, please submit your concerns to: </span><span
                class="s3">admin@watchturks.com</span><span class="s3"> for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.</span>
            </p>
            <p class="s5"><span class="s8">Reliance on Information Posted</span></p>
            <p class="s5"><span class="s3">The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, </span><span
                class="s3">completeness</span><span class="s3"> or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</span>
            </p>
            <p class="s5"><span class="s3">This Website includes content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</span>
            </p>
            <p class="s5"><span class="s8">Changes to the Website</span></p>
            <p class="s5"><span class="s3">We may update the content on this Website from time to time, but its content is not necessarily complete or </span><span
                class="s3">up-to-date</span><span class="s3">. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</span>
            </p>
            <p class="s5"><span class="s8">Information About You and Your Visits to the Website</span></p>
            <p class="s5"><span class="s3">All information we collect on this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</span>
            </p>
            <p class="s5"><span class="s8">Online Purchases and Other Terms and Conditions</span></p>
            <p class="s5"><span class="s3">All purchases through our site or other transactions for the sale of memberships formed through the Website, or </span><span
                class="s3">as a result of</span><span class="s3"> visits made by you are governed by our Terms of Sale, which are hereby incorporated into these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">Additional terms and conditions may also apply to specific portions, services or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s8">Linking to the Website and Social Media Features</span></p>
            <p class="s5"><span class="s3">You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, </span><span
                class="s3">approval</span><span class="s3"> or endorsement on our part without our express written consent.</span>
            </p>
            <p class="s5"><span
                class="s3">This Website may provide certain social media features that enable you to:</span></p>
            <p class="s5"><span class="s3">Link from your own or certain third-party websites to certain content on this Website.</span>
            </p>
            <p class="s5"><span class="s3">Send e-mails or other communications with certain content, or links to certain content, on this Website.</span>
            </p>
            <p class="s5"><span class="s3">Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</span>
            </p>
            <p class="s5"><span class="s3">You may use these features solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</span>
            </p>
            <p class="s5"><span class="s3">Establish a link from any website that is not owned by you.</span></p>
            <p class="s5"><span class="s3">Cause the Website or portions of it to be displayed, or appear to be displayed by,</span>
            </p>
            <p class="s5"><span
                class="s3">for example, framing, deep linking or in-line linking, on any other site.</span></p>
            <p class="s5"><span class="s3">Link to any part of the Website other than the homepage.</span></p>
            <p class="s5"><span class="s3">Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.</span>
            </p>
            <p class="s5"><span class="s3">You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</span>
            </p>
            <p class="s5"><span class="s3">We may disable all or any social media features and any links at any time without notice in our discretion.</span>
            </p>
            <p class="s5"><span class="s8">Links from the Website</span></p>
            <p class="s5"><span class="s3">If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or </span><span
                class="s3">resources, and</span><span class="s3"> accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access</span>
            </p>
            <p class="s5"><span class="s3">any of the </span><span class="s3">third party</span><span class="s3"> websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span>
            </p>
            <p class="s5"><span class="s8">Geographic Restrictions</span></p>
            <p class="s5"><span class="s3">The owner of the Website is based in </span><span class="s3">the United Kingdom</span><span
                class="s3">. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United Kingdom. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United Kingdom, you do so on your own initiative and are responsible for compliance with local laws.</span>
            </p>
            <p class="s5"><span class="s8">Disclaimer of Warranties</span></p>
            <p class="s5"><span class="s3">You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your </span><span
                class="s3">particular requirements</span><span class="s3"> for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span>
            </p>
            <p class="s5"><span class="s3">YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR</span>
            </p>
            <p class="s5"><span class="s3">THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</span>
            </p>
            <p class="s5"><span class="s3">THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON- INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</span>
            </p>
            <p class="s5"><span class="s3">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span>
            </p>
            <p class="s5"><span class="s8">Limitation on Liability</span></p>
            <p class="s5"><span class="s3">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY </span><span
                class="s3">SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</span>
            </p>
            <p class="s5"><span class="s3">THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span>
            </p>
            <p class="s5"><span class="s8">Indemnification</span></p>
            <p class="s5"><span class="s3">You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</span>
            </p>
            <p class="s5"><span class="s8">Governing Law and Jurisdiction</span></p>
            <p class="s5"><span class="s3">All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any other jurisdiction).</span>
            </p>
            <p class="s5"><span class="s3">Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of Florida, in each case located in the City of Tampa and County of Hillsborough, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive </span><span
                class="s3">any and all</span><span class="s3"> objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span>
            </p>
            <p class="s5"><span class="s8">Arbitration</span></p>
            <p class="s5"><span class="s3">At Company's sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Florida law.</span>
            </p>
            <p class="s5"><span class="s8">Limitation on Time to File Claims</span></p>
            <p class="s5"><span class="s3">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</span>
            </p>
            <p class="s5"><span class="s8">Waiver and Severability</span></p>
            <p class="s5"><span class="s3">No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</span>
            </p>
            <p class="s5"><span class="s3">If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, </span><span
                class="s3">illegal</span><span class="s3"> or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</span>
            </p>
            <p class="s5"><span class="s8">Entire Agreement</span></p>
            <p class="s5"><span class="s3">The Terms of Use, our Privacy Policy, and Terms of Sale constitute the sole and entire agreement between you and Fun Networks, Inc. D/B/A FNTV with respect to the Website and supersede all prior and contemporaneous understandings, agreements, </span><span
                class="s3">representations</span><span class="s3">and warranties, both written and oral, with respect to the Website.</span>
            </p>
            <p class="s5"><span class="s8">Your Comments and Concerns</span></p>
            <p class="s5"><span class="s3">This website is operated by </span><span class="s3">Watch Turks</span></p>
            <p class="s5"><span
                class="s3">All notices of copyright infringement claims should be directed to: </span><span
                class="s3">admin@watchturks.com</span></p>
            <p class="s5"><span class="s3">All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: admin@watchturks.com</span>
            </p>
            <p className={classes.mb24}>
                Thank you for choosing Watch Turks. We value your trust and are
                committed to delivering the best services for your needs.
            </p>
        </div>
    );
};

export default TermCondition;
