import React, {useContext} from 'react';
import classes from './EditProfile.module.css';
import mainClasses from '../../../index.css';
import PencilSimple from './../../../img/PencilSimple.svg';
import eyePass from './../../../img/Eyepassword.svg';
import eyeTxt from './../../../img/Eyetxt.svg';
import CaretLeft from './../../../img/CaretLeft.svg';
import {useState} from 'react';
import {leftMenu} from '../ProfileMain';
import {useMutation} from '@tanstack/react-query';
import {AuthContext} from '../../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {makeReqest} from '../../../axios';
import PlaceHolder from '../../../img/Logo.svg';
import axios from 'axios';

const EditProfile = () => {
	const [passwordType, setPasswordType] = useState('password');
	const [file, setFile] = useState(null);
	const _userName = JSON.parse(localStorage.getItem('user'))?.body.user.user_name;
	const [userProfile, setUserProfile] = useState({
		user_name: _userName,
		user_password: '',
		profile: null,
	});
	const {currentUser} = useContext(AuthContext);
	const handleChange = (e) => {
		setUserProfile((prev) => ({...prev, [e.target.name]: e.target.value}));
	};

	const ctxEdits = useContext(leftMenu);
	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const showSidebar = () => {
		ctxEdits();
	};

	const updateProfile = useMutation(
		() => {
			const formData = new FormData();
			formData.append('user_id', currentUser?.body.user.user_id);
			formData.append('user_name', userProfile.user_name);
			formData.append('user_password', userProfile.user_password);
			formData.append('profile', file);

			// return makeReqest.post('/auth/update-profile', formData, {
			// 		headers: {
			// 			'Content-Type': 'multipart/form-data',
			// 		},
			// 	}
			// );
			return axios.post(`https://admin.watchturks.online/api/auth/update-profile`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
		},
		{
			onSuccess: (data) => {
				// Invalidate and refetch
				// console.log(data);

				localStorage.setItem('user', JSON.stringify(data?.data));

				toast.success('profile updated successfully', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					toastId: 'update-movie-success',
				});
				// updateUserInfo(data?.data)
				window.location.reload();
			},
			onError: (error) => {
				toast.error(
					error.response.data.message ||
					error.response.data.error ||
					error.response?.data,
					{
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
						toastId: 'update-movie-error',
					}
				);
			},
		}
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		updateProfile.mutate();
	};

	return (
		<div className={classes.editProfileTop}>
			<div className={classes.headerContent}>
				<img
					className={classes.CaretLeft}
					onClick={showSidebar}
					src={CaretLeft}
					alt="CaretLeft"
				/>
				<img
					className={classes.editIcon}
					src={PencilSimple}
					alt="PencilSimple"
				/>
				<h1>Edit Profile</h1>
			</div>
			<div className={classes.editRow}>
				<div>
					<p className={mainClasses.mb25}>If you wish, you can change your current avatar, password, and display name (the one you use when commenting) from this page.</p>
				</div>
			</div>
			<div className={classes.editRow}>
				<div className={classes.profile}>
					<div className={classes.uploadBtn}>
						{!currentUser.body.user.image_url && !file ? (
							<img
								src={PlaceHolder}
								style={{width: '78px'}}
								alt="placeholder"
							/>
						) : file ? (
							<img
								src={URL.createObjectURL(file)}
								alt="placeholder"
							/>
						) : (
							<img
								style={{width: '178px'}}
								src={
									currentUser?.body.user.image_url.startsWith('https')
										? currentUser?.body.user.image_url
										: `${process.env.REACT_APP_BASE_URL}/uploads/${currentUser?.body.user.image_url}`
								}
								alt="userProfile"
							/>
						)}
						<div
							// class={classes.btn}
							style={{
								backgroundImage: file
									? `url(${URL.createObjectURL(file)})`
									: null,
								zIndex: '999',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								position: 'absolute',
								top: '50%',
								width: '100%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								cursor: 'pointer',
							}}
						></div>
						<input
							type="file"
							name="profile"
							onChange={(e) => setFile(e.target?.files[0])}
							value={userProfile.profile}
						/>
					</div>
					<p>Change avatar</p>
				</div>
			</div>
			<div className={classes.editRow}>
				<div className={classes.inputData}>
					<form onSubmit={handleSubmit}>
						<div className={classes.name}>
							<p>Name</p>
							<div>
                <span>
                  <input
	                  type="text"
	                  name="user_name"
	                  value={userProfile.user_name}
	                  onChange={handleChange}
	                  placeholder="Name"
                  />
                </span>
							</div>
						</div>
						<div className={classes.pass}>
							<p>Password</p>
							<div className={classes.passInner}>
                <span>
                  <input
	                  type={passwordType}
	                  onChange={handleChange}
	                  value={userProfile.user_password}
	                  name="user_password"
	                  placeholder="Password"
                  />
                  <div>
                    <button type="button" onClick={togglePassword}>
                      {passwordType === 'password' ? (
	                      <img src={eyePass} alt="eyePass"/>
                      ) : (
	                      <img src={eyeTxt} alt="eyeTxt"/>
                      )}
                    </button>
                  </div>
                </span>
							</div>
						</div>
						<div className={classes.btn}>
							<p></p>
							<button type="submit">Save</button>
						</div>
					</form>
				</div>
			</div>
			<ToastContainer limit={1}/>
		</div>
	);
};
export default EditProfile;
