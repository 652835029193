import React, {useContext, useEffect, useState} from 'react';
import Filter from '../Filter/Filter';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import classes from './AllSeries.module.css';
import {useLocation} from 'react-router-dom';
import SeriesCard from './SeriesCard';
import Spinner from '../spinner/Spinner.jsx';
import NoResult from '../Search/NoResult';
import axios from 'axios';
import {AuthContext} from '../../context/authContext';

const AllSeries = () => {
	const [query, setQuery] = useState('');
	const [allSeries, setAllSeries] = useState([]);
	const [filteredData, setFilteredData] = useState({});
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const searchQuery = searchParams.get('search');
	const {currentUser} = useContext(AuthContext);


	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		setPage(1); // Sayfayı 1'e sıfırla
		fetchData();
	}, [filteredData, searchQuery]); // searchQuery'i izle

	useEffect(() => {
		fetchData();
	}, [page, query]);

	const fetchData = async () => {
		try {
			setLoading(true);
			const queryParams = [];

			// Filtre verileri varsa sorguya ekle
			if (filteredData && (filteredData.genre || filteredData.year || filteredData.sortby)) {

				if (filteredData.genre) {
					queryParams.push(`genre=${filteredData.genre}`);
				}
				if (filteredData.year) {
					queryParams.push(`year=${filteredData.year}`);
				}
				if (filteredData.sortby) {
					queryParams.push(`sortby=${filteredData.sortby || ''}`);
				}
			}

			// Arama sorgusu varsa sorguya ekle
			if (query || searchQuery) {
				queryParams.push(`search=${query || searchQuery}`);
			}

			// Sayfa numarası ve limiti sorguya ekle
			queryParams.push(`page=${page}`);

			if (currentUser?.body.user?.user_id) {
				// Sayfa numarası ve limiti sorguya ekle
				queryParams.push(`user_id=${currentUser?.body.user?.user_id}`);
			}

			const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

			const response = await axios.get(
				`https://admin.watchturks.online/api/series${queryString}`
			);

			setAllSeries(response.data.body.series);
			setTotalPages(response.data.body.total_pages);
			setLoading(false)


		}
		catch (error) {
			console.error('Veri çekme hatası:', error);
			setLoading(false)
		}
	};

	const handleSearch = (e) => {
		setQuery(e);
		//setPage(1);
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	const getVisiblePageNumbers = () => {
		const numbers = [];
		let startPage = Math.max(1, page - 2);
		let endPage = Math.min(totalPages, page + 2);

		if (totalPages <= 5) {
			startPage = 1;
			endPage = totalPages;
		}
		else {
			if (page <= 2) {
				startPage = 1;
				endPage = 5;
			}

			if (page >= totalPages - 1) {
				startPage = totalPages - 4;
				endPage = totalPages;
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			numbers.push(i);
		}

		return numbers;
	};

	const visiblePageNumbers = getVisiblePageNumbers();

	return (
		<div className={classes.allMoviesTop}>
			<div className={classes.allmoviesInner}>
				<Header/>
				<div className={classes.moviesList}>
					<Filter
						handleSearch={handleSearch}
						handleFilterData={setFilteredData}
					/>
					<h2>All Series</h2>
					<div className={classes.row}>

						{loading ? (
							<Spinner/>
						) : (
							allSeries.length === 0 ? (
								<NoResult/>
							) : (
								allSeries.map((serie) => (
									<SeriesCard serie={serie} key={serie.serie_id}/>
								))
							)
						)}
					</div>
					{!loading && allSeries.length > 0 && (
						<div className={classes.pagination}>
							<button onClick={() => handlePageChange(1)} className={`pagination-first ${page === 1 ? 'd-noned' : ''}`} disabled={page === 1}>
								&laquo;&laquo;
							</button>

							<button onClick={() => handlePageChange(page - 1)} className={page === 1 ? 'd-noned' : ''} disabled={page === 1}>
								&laquo;
							</button>

							{page > 3 && <span className={`pagination-dot`}>...</span>}
							{visiblePageNumbers.map(num => (
								<button
									key={num}
									onClick={() => handlePageChange(num)}
									className={page === num ? classes.active : ''}
								>
									{num}
								</button>
							))}
							{page < totalPages - 2 && <span className={`pagination-dot`}>...</span>}

							<button onClick={() => handlePageChange(page + 1)} className={page === totalPages ? 'd-noned' : ''} disabled={page === totalPages}>
								&raquo;
							</button>

							<button onClick={() => handlePageChange(totalPages)} className={`pagination-last ${page === totalPages ? 'd-noned' : ''}`} disabled={page === totalPages}>
								&raquo;&raquo;
							</button>
						</div>
					)}
				</div>
				<Footer/>
			</div>
		</div>
	);
};

export default AllSeries;
