import React, {useContext} from "react";
import classes from "./PaymentDetail.module.css";
import logo from "./../../../img/Logo.svg";
import add from "./../../../img/add.svg";
import engicon from "./../../../img/engIcon.svg";
import repeat from "./../../../img/Repeat.svg";
import hd from "./../../../img/hd.svg";
import loginGif from "./../../../img/loginGif.gif";
import BellRinging from "./../../../img/BellRinging.svg";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../../context/authContext";
import {useQuery} from "@tanstack/react-query";
import {makeReqest} from "../../../axios";

const PaymentDetail = () => {
    const currentUser = useContext(AuthContext);
    const navigate = useNavigate();
    // console.log('user',currentUser.currentUser);
    return (
        <div className={classes.detailTop}>

            <div className={classes.inner}>
                <img src={loginGif} alt="loginGif" className={classes.loginGif}/>
                <img className={classes.logo} src={logo} alt="logo"/>
                <h2 className={classes.logoTxt}>WatchTurks </h2>
                <h1>
                    Welcome to Watch Turks{" "}
                </h1>
                <p>
                    The best premium website to have English subtitles for your Turkish Series!
                </p>
                <div className={classes.offerDetails}>
                    <div className={classes.card}>
                        <img src={add} alt="add"/>
                        <p>No Advertisement</p>
                    </div>
                    <div className={classes.card}>
                        <img src={engicon} alt="engicon"/>
                        <p>English Subtitles</p>
                    </div>
                    <div className={classes.card}>
                        <img src={repeat} alt="repeat"/>
                        <p>Unlimited Support</p>
                    </div>
                </div>

                <Link className={classes.choosePlan} to="/signin">
                    Login Now
                </Link>
                <Link className={classes.registerButton} to="/register">
                    Register
                </Link>
            </div>
        </div>
    );
};

export default PaymentDetail;
