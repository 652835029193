import React, {useContext} from 'react';
import classes from './History.module.css';
import clock from './../../../img/Clock.svg';
import NotSubscribe from '../../NotSubscribe/NotSubscribe';
import {useState} from 'react';
import CaretLeft from './../../../img/CaretLeft.svg';
import {leftMenu} from '../ProfileMain';
import {AuthContext} from '../../../context/authContext';
import {useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import MovieCard from '../../AllMovies/MovieCard';
import SeriesCard from '../../AllSeries/SeriesCard';
import axios from 'axios';

const History = () => {
	const {currentUser} = useContext(AuthContext);

	const ctx = useContext(leftMenu);
	const [sidebar, setSidebar] = useState(false);

	const showSidebar = () => {
		setSidebar(!sidebar);
		ctx();
	};

	// const closeSideBar = () => {
	//   setSidebar(false);
	// }

	//   to just hide warnings

	const {data: history } = useQuery({
		queryKey: ['userHistory'],
		queryFn: () => {
			return axios.get(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/history`);
		},
		refetchOnWindowFocus: false,
	});


	const uniqueHistory = [];

	if (history?.data.body.history) {
		history.data.body.history.forEach((item) => {
			if (item.series) {
				const isDuplicate = uniqueHistory.some((uniqueItem) => uniqueItem.series?.serie_id === item.series?.serie_id);
				if (!isDuplicate) {
					uniqueHistory.push(item);
				}
			} else if (item.movie) {
				uniqueHistory.push(item);
			}
		});
	}

	return (
		<div
			className={
				sidebar
					? `${classes.historyTop} ${classes.active2}`
					: `${classes.historyTop}`
			}
		>
			{currentUser?.body.user.user_status ? (
				<>
					<div className={classes.headerContent}>
						<img
							className={classes.CaretLeft}
							onClick={showSidebar}
							src={CaretLeft}
							alt="CaretLeft"
						/>
						<img className={classes.bookmarkHeader} src={clock} alt="clock"/>
						<h1>History</h1>
					</div>
					<div className={classes.moviesList}>
						{uniqueHistory.map((item, index) => (
							<React.Fragment key={index}>
								{item.movie && (
									<MovieCard movie={item.movie} />
								)}
								{item.series && (
									<SeriesCard serie={item.series} />
								)}
							</React.Fragment>
						))}
					</div>

				</>
			) : (
				<NotSubscribe/>
			)}

			{/* {!currentUser?.body.user.user_status && <NotSubscribe />} */}
		</div>
	);
};
export default History;
