import React from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import MoviesSlider from '../_MoviesSlider';

const Recommended = () => {
	const {data, isSuccess, isLoading} = useQuery({
		queryKey: ['recomended'],
		queryFn: () => {
			const userId = JSON.parse(localStorage.getItem('user'))?.body.user.user_id ? '?userId=' + JSON.parse(localStorage.getItem('user'))?.body.user.user_id : '';

			return axios.get(`https://admin.watchturks.online/api/recommendations` + userId);
		},
		refetchOnWindowFocus: false,
	},);

	return <MoviesSlider title={'Recommended'} data={data?.data.body.movies} isLoading={isLoading}/>;
};

export default Recommended;
