import React, {useContext} from "react";
import classes from "./PrivacyPolicy.module.css";
import ShieldCheck from "./../../../img/ShieldCheck.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import {leftMenu} from "../ProfileMain";

const PrivacyPolicy = () => {
    const ctxPrivacy = useContext(leftMenu);

    const showSidebar = () => {
        ctxPrivacy();
    };

    return (
        <div className={classes.privacyPolicyTop}>
            <div className={classes.privacyHeader}>
                <img
                    className={classes.CaretLeft}
                    onClick={showSidebar}
                    src={CaretLeft}
                    alt="CaretLeft"
                />
                <img
                    className={classes.ShieldCheck}
                    src={ShieldCheck}
                    alt="ShieldCheck"
                />
                <h1>Website Privacy Policy</h1>
            </div>

            <div className={classes.detail}>
                <h5>Last modified: December 31, 2023</h5>
                <h2>Introduction</h2>
                <p className={classes.mb24}>
                    Watch Turks ("Company" or "We") respect your privacy and are
                    committed to protecting it through our compliance with this policy.
                    This policy describes the types of information we may collect from you or that you may provide when
                    you visit the website https://www.funnetworktv.com/ (our "Website") and our practices for
                    collecting, using, maintaining, protecting, and disclosing that information.
                    This policy applies to the information we collect:
                </p>
                <h2>On this Website.</h2>

                <p className={classes.mb16}>
                    In email, text, and other electronic messages between you and this Website.
                    Through mobile and desktop applications you download from this Website, which provide dedicated
                    non-browser-based interaction between you and this Website.
                    When you interact with our advertising and applications on third-party websites and services, if
                    those applications or advertising include links to this policy.
                    It does not apply to information collected by:
                    Us offline or through any other means, including on any other website operated by Company or any
                    third party; or
                    Any third party, including through any application or content (including advertising) that may link
                    to or be accessible from or on the Website.
                    Please read this policy carefully to understand our policies and practices regarding your
                    information and how we will treat it. If you do not agree with our policies and practices, your
                    choice is not to use our Website. By accessing or using this Website, you agree to this privacy
                    policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued
                    use of this Website after we make changes is deemed to be acceptance of those changes, so please
                    check the policy periodically for updates.
                </p>
                <h3>Children Under the Age of 16
                </h3>
                <p className={classes.mb16}>
                    Our Website is not intended for children under 16 years of age. No one under age 16 may provide any
                    information to or on the Website. We do not knowingly collect personal information from children
                    under 16. If you are under 16, do not use or provide any information on this Website or on or
                    through any of its features. If we learn we have collected or received personal information from a
                    child under 16 without verification of parental consent, we will delete that information. If you
                    believe we might have any information from or about a child under 16, please contact us at:
                    admin@watchturks.com
                    California residents under 16 years of age may have additional rights regarding the collection and
                    sale of their personal information. Please see Your California Privacy Rights for more information.

                </p>
                <h3>Information We Collect About You and How We Collect It
                </h3>
                <p className={classes.mb16}>
                    We collect several types of information from and about users of our Website, including information:
                    By which you may be personally identified, such as name, postal address, e-mail address, telephone
                    number, or any other identifier by which you may be contacted online or offline ("personal
                    information");
                    That is about you but individually does not identify you; and/or
                    About your internet connection, the equipment you use to access our Website, and usage details. We
                    collect this information:
                    Directly from you when you provide it to us.
                    Automatically as you navigate through the site. Information collected automatically may include
                    usage details, IP addresses, and information collected through cookies, web beacons, and other
                    tracking technologies.
                    From third parties, for example, our business partners.
                </p>
                <h3>Information You Provide to Us.
                </h3>
                <p className={classes.mb16}>
                    The information we collect on or through our Website may include:
                    Information that you provide by filling in forms on our Website. This includes information provided
                    at the time of registering to use our Website, subscribing to our service, posting material, or
                    requesting further services. We may also ask you for information when you enter a contest or
                    promotion sponsored by us, and when you report a problem with our Website.
                    Records and copies of your correspondence (including email addresses), if you contact us.
                    Your responses to surveys that we might ask you to complete for research purposes.
                    Details of transactions you carry out through our Website and of the fulfillment of your orders. You
                    may be required to provide financial information before placing an order through our Website.
                    Your search queries on the Website.
                    You also may provide information to be published or displayed (hereinafter, "posted") on public
                    areas of the Website, or transmitted to other users of the Website or third parties (collectively,
                    "User Contributions"). Your User Contributions are posted on and transmitted to others at your own
                    risk. Although you may set certain privacy settings for such information by logging into your
                    account profile, please be aware that no security measures are perfect or impenetrable.
                    Additionally, we cannot control the actions of other users of the Website with whom you may choose
                    to share your User Contributions. Therefore, we cannot and do not guarantee that your User
                    Contributions will not be viewed by unauthorized persons.
                </p>
                <h3>Information We Collect Through Automatic Data Collection Technologies.
                </h3>
                <p className={classes.mb24}>
                    As you navigate through and interact with our Website, we may use automatic data collection
                    technologies to collect certain information about your equipment, browsing actions, and patterns,
                    including:
                    Details of your visits to our Website, including traffic data, location data, logs, and other
                    communication data and the resources that you access and use on the Website.
                    Information about your computer and internet connection, including your IP address, operating
                    system, and browser type.
                    The information we collect automatically may include personal information, or we may maintain it or
                    associate it with personal information we collect in other ways or receive from third parties. It
                    helps us to improve our Website and to deliver a better and more personalized service, including by
                    enabling us to:
                    Estimate our audience size and usage patterns.
                    Store information about your preferences, allowing us to customize our Website
                    according to your individual interests.
                    Speed up your searches.
                    Recognize you when you return to our Website.
                    The technologies we use for this automatic data collection may include:
                </p>
                <h2>Cookies (or browser cookies)</h2>
                <p className={classes.mb24}>
                    A cookie is a small file placed on the hard drive of your
                    computer. You may refuse to accept browser cookies by activating the appropriate setting on your
                    browser. However, if you select this setting you may be unable to access certain parts of our
                    Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system
                    will issue cookies when you direct your browser to our Website.
                    Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to
                    collect and store information about your preferences and navigation to, from, and on our Website.
                    Flash cookies are not managed by the same browser settings as are used for browser cookies. For
                    information about managing your privacy and security settings for Flash cookies, see Choices About
                    How We Use and Disclose Your Information.
                    Web Beacons. Pages of our Website and our emails may contain small electronic files known as web
                    beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                    permit the Company, for example, to count users who have visited those pages or opened an email and
                    for other related website statistics (for example, recording the popularity of certain website
                    content and verifying system and server integrity).
                </p>
                <h2>Third-Party Use of Cookies and Other Tracking Technologies
                </h2>
                <p className={classes.mb24}>
                    Some content or applications, including advertisements, on the Website are served by third-parties,
                    including advertisers, ad networks and servers, content providers, and application providers. These
                    third parties may use cookies alone or in conjunction with web beacons or other tracking
                    technologies to collect information about you when you use our website. The information they collect
                    may be associated with your personal information or they may collect information, including personal
                    information, about your online activities over time and across different websites and other online
                    services. They may use this information to provide you with interest-based (behavioral) advertising
                    or other targeted content.
                    We do not control these third parties' tracking technologies or how they may be used. If you have
                    any questions about an advertisement or other targeted content, you should contact the responsible
                    provider directly. For information about how you can opt out of receiving targeted advertising from
                    many providers, see Choices About How We Use and Disclose Your Information.
                </p>
                <h2>How We Use Your Information
                </h2>
                <p className={classes.mb24}>
                    We use information that we collect about you or that you provide to us, including any personal
                    information:
                    To present our Website and its contents to you.
                    To provide you with information, products, or services that you request from us.
                    To fulfill any other purpose for which you provide it.
                    To provide you with notices about your account, including expiration and renewal notices.
                    To carry out our obligations and enforce our rights arising from any contracts entered into between
                    you and us, including for billing and collection.
                    To notify you about changes to our Website or any products or services we offer or provide though
                    it.
                    To allow you to participate in interactive features on our Website.
                    In any other way we may describe when you provide the information.
                    For any other purpose with your consent.
                    We may also use your information to contact you about our own and third-parties' goods and services
                    that may be of interest to you. If you do not want us to use your information in this way, please
                    check the relevant box located on the form on which we collect your data (the registration form).
                    For more information, see Choices About How We Use and Disclose Your Information.
                    We may use the information we have collected from you to enable us to display advertisements to our
                    advertisers' target audiences. Even though we do not disclose your personal information for these
                    purposes without your consent, if you click on or otherwise interact with an advertisement, the
                    advertiser may assume that you meet its target criteria.
                </p>
                <h2>Disclosure of Your Information

                </h2>
                <p className={classes.mb24}>
                    We may disclose aggregated information about our users, and information that does not identify any
                    individual, without restriction.
                    We may disclose personal information that we collect or you provide as described in this privacy
                    policy:
                    To our subsidiaries and affiliates.
                    To contractors, service providers, and other third parties we use to support our business.
                    To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                    dissolution, or other sale or transfer of some or all of Watch Turks' assets, whether as a going
                    concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
                    held by Watch Turks about our Website users is among the assets transferred.
                    To third parties to market their products or services to you if you have not opted out of these
                    disclosures. For more information, see Choices About How We Use and Disclose Your Information.
                    To fulfill the purpose for which you provide it. For example, if you give us an email address to use
                    the "email a friend" feature of our Website, we will transmit the contents of that email and your
                    email address to the recipients.
                    For any other purpose disclosed by us when you provide the information.
                    With your consent.
                    We may also disclose your personal information:
                    To comply with any court order, law, or legal process, including to respond to any government or
                    regulatory request.
                    and other agreements, including for billing and collection purposes.
                    If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                    Watch Turks, our customers, or others. This includes exchanging information with other companies and
                    organizations for the purposes of fraud protection and credit risk reduction.
                </p>
                <h2>Choices About How We Use and Disclose Your Information


                </h2>

                <p className={classes.mb24}>
                    We strive to provide you with choices regarding the personal information you provide to us. We have
                    created mechanisms to provide you with the following control over your information:
                    Tracking Technologies and Advertising. You can set your browser to refuse all or some browser
                    cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie
                    settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies,
                    please note that some parts of this site may then be inaccessible or not function properly.
                    Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your
                    personal information with unaffiliated or non-agent third parties for promotional purposes, you can
                    opt out by sending us an email to admin@watchturks.com. You can also always opt out by sending us an
                    email stating your request to support@funnetworktv.com.
                    Promotional Offers from the Company. If you do not wish to have your contact information used by the
                    Company to promote our own or third parties' products or services, you can opt out by sending us an
                    email to admin@watchturks.com or at any other time by sending us an email stating your request to
                    admin@watchturks.com. If we have sent you a promotional email, you may send us a return email asking
                    to be omitted from future email distributions. This opt out does not apply to information provided
                    to the Company as a result of a product purchase, warranty registration, product service experience,
                    or other transactions.
                    Targeted Advertising. If you do not want us to use information that we collect or that you provide
                    to us to deliver advertisements according to our advertisers' target-audience preferences, you can
                    opt out by sending us an email to admin@watchturks.com or at any other time by sending us an email
                    stating your request to admin@watchturks.com.
                    We do not control third parties' collection or use of your information to serve interest- based
                    advertising. However, these third parties may provide you with ways to choose not to have your
                    information collected or used in this way. You can opt out of receiving targeted ads from members of
                    the Network Advertising Initiative ("NAI") on the NAI's website.
                    California residents may have additional personal information rights and choices. Please see Your
                    California Privacy Rights for more information.
                    Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes
                    Chapter 603A may submit a request to this designated address: admin@watchturks.com.
                </p>
                <h2>
                    Accessing and Correcting Your Information

                </h2>
                <p className={classes.mb24}>
                    You can review and change your personal information by logging into the Website and visiting your
                    account profile page.
                    You may also send us an email at admin@watchturks.com to request access to, correct, or delete any
                    personal information that you have provided to us. We cannot delete your personal information except
                    by also deleting your user account. We may not accommodate a request to change information if we
                    believe the change would violate any law or legal requirement or cause the information to be
                    incorrect.
                </p>
                <h2>
                    Data Security

                </h2>
                <p className={classes.mb24}>
                    We have implemented measures designed to secure your personal information from accidental loss and
                    from unauthorized access, use, alteration, and disclosure. All information you provide to us is
                    stored on our secure servers behind firewalls. Any payment transactions will be encrypted.
                    The safety and security of your information also depends on you. Where we have given you (or where
                    you have chosen) a password for access to certain parts of our Website, you are responsible for
                    keeping this password confidential. We ask you not to share your password with anyone. We urge you
                    to be careful about giving out information in public areas of the Website like message boards. The
                    information you share in public areas may be viewed by any user of the Website.
                    Unfortunately, the transmission of information via the internet is not completely secure. Although
                    we do our best to protect your personal information, we cannot guarantee the security of your
                    personal information transmitted to our Website. Any transmission of personal information is at your
                    own risk. We are not responsible for circumvention of any privacy settings or security measures
                    contained on the Website.
                </p>
                <h2>Changes to Our Privacy Policy
                </h2>
                <p className={classes.mb24}>
                    It is our policy to post any changes we make to our privacy policy on this page. If we make material
                    changes to how we treat our users' personal information, we will notify you by email to the email
                    address specified in your account. The date the privacy policy was last revised is identified at the
                    top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email
                    address for you, and for periodically visiting our Website and this privacy policy to check for any
                    changes.

                </p>
                <h2>Contact Information
                </h2>
                <p>
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at:
                    admin@watchturks.com
                </p>
                <p>
                    Regards, <br/>
                    Watch Turks
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
