import React from "react";
import classes from "./Star.module.css";

// import { AiFillStar } from "react-icons/ai";
import Rate from "./Rate";
const StarRating = ({ handlestarReview }) => {
  const selectedStars = (stars) => {
    handlestarReview(stars);
  };
  return (
    <div className={classes.starRating}>
      <Rate selectedStars={selectedStars} />
    </div>
  );
};

export default StarRating;
