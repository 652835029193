import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Next from "./../../StaticPic/Next.png";
import play from "./../../img/Play.svg";
import "./carousel.css";
import { multiData } from "./data";
import { multiData2 } from "./data2";
// import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useState } from "react";
import { useEffect } from "react";
let slidesToShow = 5;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          {/* <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} /> */}
          <img className="carouselPrev" src={Next} alt="next" />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          {/* <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} /> */}
          <img className="carouselNext" src={Next} alt="next" />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 2050,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
  ],
};

const MultiItemCarousel = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  const [seriesShow, setToggleSeries] = useState(1);

  const toggleSeriesHandler = (index) => {
    setToggleSeries(index);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 2;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 2;
  } else if (width > 1025 && width <= 1920) {
    slidesToShow = 3;
  } else {
    slidesToShow = 3;
  }

  return (
    <div className="carousel">
      <div className="seasonCount">
        <h2
          onClick={() => {
            toggleSeriesHandler(1);
          }}
          className={seriesShow === 1 ? "tab ActiveTab" : "tab"}
        >
          Season 1
        </h2>
        <h2
          onClick={() => {
            toggleSeriesHandler(2);
          }}
          className={seriesShow === 2 ? "tab ActiveTab" : "tab"}
        >
          Season 2
        </h2>
      </div>
      <div
        className={
          seriesShow === 1 ? "tabContent findActiveContent" : "tabContent"
        }
      >
        <Slider {...carouselProperties}>
          {multiData2.map((item) => (
            <Card item={item} />
          ))}
        </Slider>
      </div>
      <div
        className={
          seriesShow === 2 ? "tabContent findActiveContent" : "tabContent"
        }
      >
        <Slider {...carouselProperties}>
          {multiData.map((item) => (
            <Card item={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ item }) => {
  return (
    <div className="card">
      <img className="playSeriesEp" src={play} alt="play" />
      <div className="posterSeries">
        <img
          className="multi__image"
          src={item}
          alt=""
          style={{
            width: "100%",
            objectFit: "contain",
          }}
        />
      </div>
      <h2>Season 1 Episode 2 </h2>
      <p>59 min</p>
    </div>
  );
};

export default MultiItemCarousel;
