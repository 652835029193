import React, {useContext, useState, useEffect} from 'react';
import classes from './SliderCard.module.css';

import star from './../../img/star.svg';
import save from './../../img/save.svg';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {makeReqest} from '../../axios';
import {useNavigate} from 'react-router';
import filledWatchLaterIcon from '../.././img/BookmarkSimple.svg';
import {AuthContext} from '../../context/authContext';
import axios from 'axios';

const SliderCard = ({movieItem, isClickAllowed}) => {
	const [isSwiping, setIsSwiping] = useState(false);
	const [isWhishlist, setIsWhishlists] = useState(movieItem.is_wishlist);
	const handleSwipeStart = () => {
		setIsSwiping(true);
	};

	const handleSwipeEnd = () => {
		setIsSwiping(false);
	};

	const handleLinkClick = (e) => {
		if (isSwiping) {
			e.preventDefault();
		}
		else if (isClickAllowed) {
			navigate(`/moviedetail/${movieItem?.movie_id}#details`);
		}
	};

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const currentUser = useContext(AuthContext);
	const addToWatchList = useMutation({
		mutationFn: () => {
			return axios.post(`https://admin.watchturks.online/api/user/${currentUser.currentUser?.body.user?.user_id}/wishlist/movies/${movieItem?.movie_id}`);
		},
		onSuccess: (response) => {
			setIsWhishlists(response.data.body.message.includes('added') ? '1': '0');
			queryClient.invalidateQueries(['recomended']);
		},
	});

	const genreText = () => {
		let _text = null;
		if (movieItem?.genre_title) {
			_text = movieItem.genre_title;
		}
		else if (movieItem?.genres?.length) {
			_text = movieItem.genres
				.slice(0, 2)
				.map((genre) => genre.genre_title)
				.join(', ');
		}
		return _text;
	};

	// useEffect(() => {
	//   console.log(isDragging, movieItem.movie_title)
	// }, [isDragging])

	// console.log(movieItem);
	return (
		<div className={classes.cardUiTop} onTouchStart={handleSwipeStart} onTouchEnd={handleSwipeEnd} onClick={handleLinkClick}>
			<div className={classes.headerCard}>
				<div className={classes.rating}>
					{(movieItem?.avg_rating && movieItem?.avg_rating != '0') && (
						<div>
							<img className={classes.star} src={star} alt="star"/>
							<h3>{parseFloat(parseInt(movieItem?.avg_rating)) || ''}</h3>
						</div>
					)}

					{currentUser.currentUser?.body.user.user_status && (
						<img
							className={classes.save}
							src={isWhishlist == '1' ? filledWatchLaterIcon : save}
							alt="save"
							onClick={(e) => {
								e.stopPropagation();
								addToWatchList.mutate();
							}}
						/>
					)}
					{/* {currentUser.currentUser?.body.user.user_status && (
            <img
              className={classes.bookmark}
              src={
                movieItem?.is_notification_on
                  ? NotificationFilled
                  : bookmark
              }
              alt="bookmark"
            />
          )} */}
				</div>
				<img
					className={classes.imgDefault}
					src={`${process.env.REACT_APP_BASE_URL}/uploads/${movieItem?.movie_image}`}
					alt="top series"
					// style={{ width: "400px", height: "560px" }}
				/>

				<div className={classes.hoverCard}>
					<div className={classes.details}>
						<h4>
							{movieItem?.movie_title}

							{/* /{" "}
              {movieItem?.movie_duration} */}
						</h4>
						<p>{movieItem?.movie_description}</p>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<h3>{movieItem?.movie_title}</h3>
				<p>
					{movieItem?.movie_year} {genreText() && <span style={{position: 'relative', top: '-4px', marginLeft: '10px'}}>.</span>} <span style={{marginLeft: '10px'}}>{genreText()}</span>
				</p>
			</div>
		</div>
	);
};
export default SliderCard;
